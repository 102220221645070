import React, {useEffect} from "react"
import styled from "@emotion/native"
import {colors} from "@civitime/library/storybook/configs/colors"
import {QuestionEnded} from "./QuestionEndedScreen"
import {QuestionsCards} from "./QuestionsCards"
import {useTranslator, T} from '../translations/translate'

export const Question = ({
                           screenDimensions,
                           mobile,
                           question,
                           position,
                           questionsNumber,
                           sendResult,
                           result,
                           launchNextQuestion,
                           onLayout,
                           showResults,
                           contentTranslations,
                           updateQuestionsTimeStamps,
                           currentQuestion,
                           updateCurrentQuestion,
                           nextQuestion,
                           phase
                         }) => {
  const {t} = useTranslator()

  useEffect(() => {
    if (currentQuestion === question?.id && !Object.keys(result || {})?.some((id) => id === question.id)) {
      updateQuestionsTimeStamps((e) => ({
        ...e,
        [question.id]: {
          ...e?.[question.id],
          timeStampQuestionStart: new Date()
        }
      }))
    }
    if (Object.keys(result || {})?.some((id) => id === question.id)) {
      updateQuestionsTimeStamps((e) => ({
        ...e,
        [question.id]: {
          ...e?.[question.id],
          timeStampQuestionEnd: new Date(),
          timeStampExplanationStart: new Date()
        }
      }))
    }
  }, [currentQuestion, result])

  return (
    <WrapperQuestion
      screenDimensions={screenDimensions}
      mobile={mobile}
      position={position}
      resultTime={Object.keys(result || {})?.some((id) => id === question.id)}
      onLayout={onLayout}
    >
      <QuestionHeader>
        <QuestionHeaderText>
          <T path={"app.question"}/> &nbsp;
          <QuestionHeaderCounterText>
            {position + 1}/{questionsNumber}
          </QuestionHeaderCounterText>
        </QuestionHeaderText>
      </QuestionHeader>
      {Object.keys(result || {})?.some((id) => id === question.id) ? (
        <QuestionEnded
          question={question}
          result={result}
          updateQuestionsTimeStamps={updateQuestionsTimeStamps}
          launchNextQuestion={() => {
            updateQuestionsTimeStamps((e) => ({
              ...e,
              [question.id]: {
                ...e?.[question.id],
                timeStampExplanationEnd: new Date()
              },
            }))
            if(nextQuestion){
              updateCurrentQuestion(nextQuestion)
            }
            return launchNextQuestion()
          }}
          quizEnded={position + 1 === questionsNumber}
          showResults={() => {
            updateQuestionsTimeStamps((e) => ({
              ...e,
              [question.id]: {
                ...e?.[question.id],
                timeStampExplanationEnd: new Date()
              },
            }))
            return showResults()
          }}
          mobile={mobile}
          contentTranslations={contentTranslations}
          phase={phase}
        />
      ) : (
        <QuestionsCards
          question={question}
          mobile={mobile}
          screenDimensions={screenDimensions}
          sendResult={sendResult}
          phase={phase}
          contentTranslations={contentTranslations}
        />
      )}
    </WrapperQuestion>
  )
}

const WrapperQuestion = styled.View(
  ({screenDimensions, mobile, position, resultTime}) => ({
    justifyContent: "center",
    width: mobile
      ? screenDimensions.width * 0.9
      : screenDimensions.width * 0.66,
    height: mobile
      ? screenDimensions.height * 0.8
      : screenDimensions.height * 0.66,
    maxWidth: 840,
    maxHeight: 480,
    left: mobile
      ? (screenDimensions.width - screenDimensions.width * 0.9) / 2 +
      screenDimensions.width * position
      : (screenDimensions.width -
      (screenDimensions.width * 0.66 <= 840
        ? screenDimensions.width * 0.66
        : 840)) /
      2 +
      screenDimensions.width * position,
  })
)

const QuestionHeader = styled.View({
  height: 33,
  backgroundColor: colors.dropShadow,
  borderTopRightRadius: 9,
  borderTopLeftRadius: 9,
  justifyContent: "center",
  alignItems: "center",
})

const QuestionHeaderText = styled.Text({
  textTransform: "uppercase",
  fontSize: 15,
  color: colors.lightBackground,
})

const QuestionHeaderCounterText = styled(QuestionHeaderText)({
  fontWeight: "bold",
})
