import {campaignSet} from "../../../../saga-tools/Data.effects"

export function* playerFragmentsUpdated(event) {
  yield campaignSet(`states/${event.target}/fragments`,
    (fragments) => fragments ? fragments + 1 : 1)
}

export function* playerFragmentThemeUpdated(event) {
  yield campaignSet(`states/${event.target}/fragments/${event.theme}`,1)
}

export function* finalDestinationDiscovered(event) {
  yield campaignSet(`states/${event.target}/finalDestinationDiscovered`,
     true
  )
}

export function* finalDestinationModalSeen(event) {
  yield campaignSet(`states/${event.target}/finalDestinationModalSeen`,
    true
  )
}
