import { campaignGet, campaignSet } from '../../../../saga-tools/Data.effects'
import { nanoid }                        from 'nanoid'

export function * moduleEnded (event) {
  yield campaignSet(`sessions/${event.target}/${event.module}`,
    (session) => ({
      ...session,
      id: event.module,
      levelsDone: {
        ...session?.levelsDone,
        [event.level]: {
          id: event.level,
          position: event.tilePosition,
          destination: event.destination,
          timeStamp: new Date()
        }
      },
      nextLevel: event.nextLevel
    }))
}

export function * moduleEndedV2 (event) {
  yield campaignSet(`sessions/${event.target}/${event.theme}`,
    (session) => ({
      ...session,
      id: event.theme,
      levelsDone: {
        ...session?.levelsDone,
        [event.level]: {
          id: event.level,
          position: event.tilePosition,
          destination: event.destination,
          timeStamp: new Date(),
          type: event.module
        }
      },
      nextLevel: event.nextLevel
    }))
}

export function * moduleFailed (event) {
  yield campaignSet(`sessions/${event.target}/${event.module}`,
    (session) => ({
      ...session,
      id: event.module,
      levelsFailed: {
        ...session?.levelsFailed,
        [event.level]: {
          ...session?.levelsFailed?.[event.level],
          [nanoid()]: {
            id: event.level,
            failed: new Date()
          }
        }
      }
    }))
}

export function * moduleFailedV2 (event) {
  yield campaignSet(`sessions/${event.target}/${event.theme}`,
    (session) => ({
      ...session,
      id: event.theme,
      levelsFailed: {
        ...session?.levelsFailed,
        [event.level]:{
          ...session?.levelsFailed?.[event.level],
          [nanoid()]: {
            id: event.level,
            failed: new Date()
          }
        }
      },
    }))
}

export function * bossModuleFailed (event){
  yield campaignSet(`sessions/${event.target}/${event.theme}`,
    (session) => ({
      ...session,
      id: event.theme,
      levelsFailed: {
        ...session?.levelsFailed,
        [event.level]:{
          ...session?.levelsFailed?.[event.level],
          [nanoid()]: {
            id: event.level,
            failed: new Date(),
            boss: true,
            result: event?.result
          }
        }
      },
    }))
}


export function * challengeModuleEnded (event) {
  const challenge = yield campaignGet(`sessions/${event.target}/challenges/${event.challengeId}`)
  const newChallenge = {
    ...challenge,
    win: event.win,
    result: event.result || 0,
    status: 'ended'
  }
  yield campaignSet(`sessions/${event.target}/challenges/${event.challengeId}`, newChallenge)
  if (event.opponentId.startsWith('bot--')) return
  yield campaignSet(`sessions/${event.opponentId}/challenges/${event.challengeId}`, (challenge) => ({
    ...challenge,
    opponentResult: event.result || 0
  }))
}

export function * challengeModuleSeen (event) {
  const challenge = yield campaignGet(`sessions/${event.target}/challenges/${event.challengeId}`)
  yield campaignSet(`sessions/${event.target}/challenges/${event.challengeId}`,
    (challenge) => ({
        ...challenge,
        win: event.win,
        result: event.result || 0,
        status: 'seen',
      }
    ))
  yield campaignSet(`sessions/${challenge.opponentUid}/challenges/${event.challengeId}`, (challenge) => ({
    ...challenge,
    opponentResult: event.result
  }))
}

export function * opponentChallengeModuleSeen (event) {
  const challenge = yield campaignGet(`sessions/${event.target}/challenges/${event.challengeId}`)
  yield campaignSet(`sessions/${event.opponentId}/challenges/${event.challengeId}`,
    (challenge) => ({
        ...challenge,
        win: false,
        result: 0,
        status: 'seen',
      }
    ))
  yield campaignSet(`sessions/${challenge.opponentUid}/challenges/${event.challengeId}`, (challenge) => ({
    ...challenge,
    opponentResult: 0
  }))
}
