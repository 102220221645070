import { formatMastermindDataV2, formatQuizDataV2, formatMemoryDataV2, formatThemes } from './Content.helpersV2'

export const formatContent = (content, useThemes) => {
  if (useThemes){
    const { themes, themeTexts, settings} = formatThemes(content.themes)
    return ({
      content: {
        memory: formatMemoryDataV2(content.memory, themes).content,
        mastermind: formatMastermindDataV2(content.mastermind, themes).content,
        quiz: formatQuizDataV2(content.quizHarbor, themes).content,
      },
      texts: {
        memory: formatMemoryDataV2(content.memory, themes).texts,
        mastermind: formatMastermindDataV2(content.mastermind, themes).texts,
        quiz: formatQuizDataV2(content.quizHarbor, themes).texts,
        themes: themeTexts
      },
      themes: settings
    })
  }
  return ({
    content: {
      memory: formatMemoryData(content.memory).content,
      mastermind: formatMastermindData(content.mastermind).content,
      quizHarbor: formatQuizData(content.quizHarbor).content,
      quizTunnel: formatQuizData(content.quizTunnel).content,
    },
    texts: {
      memory: formatMemoryData(content.memory).texts,
      mastermind: formatMastermindData(content.mastermind).texts,
      quizHarbor: formatQuizData(content.quizHarbor, 'quizHarbor').texts,
      quizTunnel: formatQuizData(content.quizTunnel, 'quizTunnel').texts,
    },
  })
}
export const formatMemoryData = (memory) => {
  let themes = {}
  const memoryTexts = (memory || []).reduce((acc, value, i) => {
    const currentMemory = { ...value }
    const modalPnjEnd = value.M
    const theme = value.B
    const level = `lv-${currentMemory.A}`
    themes = {
      ...themes,
      [`${level}`]: { theme: theme }
    }
    delete currentMemory.A
    delete currentMemory.B
    delete currentMemory.M
    delete currentMemory.N
    const images = Object.values(currentMemory).filter(Boolean)
    const memory = images.reduce((acc, v, i) => {
      return ({
        ...acc,
        [`memory.${i + 1}.text`]: v,
      })
    }, {})
    return ({
      ...acc,
      [level]: {
        memory: memory,
        [`${level}.outro`]: modalPnjEnd
      }
    })
  }, {})
  const memoryData = (memory || []).reduce((acc, value) => {
    const currentMemory = { ...value }
    const level = `lv-${currentMemory.A}`
    delete currentMemory.A
    delete currentMemory.B
    delete currentMemory.M
    delete currentMemory.N
    const images = Object.values(currentMemory).filter(Boolean)
    const memory = images.map((v, i) => {
      return ({
        text: `memory.${i + 1}.text`,
        name: `image${i + 1}`,
        image: `image${i + 1}.png`
      })
    })
    if (!value) return acc
    return ({
      ...acc,
      [level]: {
        memory: memory,
        moduleEndMessage: `${level}.outro`
      }
    })
  }, {})
  
  return {
    content: memoryData,
    texts: memoryTexts
  }
}

export const formatMastermindData = (mastermind) => {
  const mastermindContent = mastermind.reduce((acc, value) => {
    if (!value) return
    const level = `lv-${value.A}`
    const intro = `lv-${value.A}.intro`
    const question = `lv-${value.A}.question`
    const outro = `lv-${value.A}.outro`
    const answers = [{
      label: value.D ? `answers.0.label` : null,
      explanation: `answers.0.explanation`,
      position: 0
    },
      {
        label: value.F ? `answers.1.label` : null,
        explanation: `answers.1.explanation`,
        position: 1
      },
      {
        label: value.H ? `answers.2.label` : null,
        explanation: `answers.2.explanation`,
        position: 2
      },
      {
        label: value.J ? `answers.3.label` : null,
        explanation: `answers.3.explanation`,
        position: 3
      },
      {
        label: value.L ? `answers.4.label` : null,
        explanation: `answers.4.explanation`,
        position: 4
      }]
      .filter(v => v.label && v.position !== undefined && v.position !== null)
    return ({
      ...acc,
      [level]: {
        intro: intro,
        question: question,
        outro: outro,
        answers: answers,
      }
    })
  }, {})
  
  const mastermindTexts = mastermind.reduce((acc, value) => {
    if (!value) return
    const level = `lv-${value.A}`
    const intro = value.B
    const question = value.C
    const outro = value.N
    const answers = {
      [`answers.0.label`]: value.D,
      [`answers.0.explanation`]: value.E,
      [`answers.1.label`]: value.F,
      [`answers.1.explanation`]: value.G,
      [`answers.2.label`]: value.H,
      [`answers.2.explanation`]: value.I,
      [`answers.3.label`]: value.J,
      [`answers.3.explanation`]: value.K,
      [`answers.4.label`]: value.L,
      [`answers.4.explanation`]: value.M,
    }
    return ({
      ...acc,
      [level]: {
        [`lv-${value.A}.intro`]: intro,
        [`lv-${value.A}.question`]: question,
        [`lv-${value.A}.outro`]: outro,
        answers: answers,
      }
    })
  }, {})
  return {
    content: mastermindContent,
    texts: mastermindTexts
  }
}

export const formatQuizData = (quiz) => {
  const quizContent = quiz.reduce((acc, value, i) => {
    if (!value) return
    const level = `lv-${value.B}`
    const question = `question.${i}.question`
    const explanation = `question.${i}.explanation`
    const endedExplanation = `question.${i}.endedExplanation`
    const answers = shuffle([{
      goodAnswer: true,
      text: value.D ? `question.${i}.answer.1.text` : null,
    }, {
      goodAnswer: false,
      text: value.E ? `question.${i}.answer.2.text` : null,
    }, {
      goodAnswer: false,
      text: value.F ? `question.${i}.answer.3.text` : null,
    }, {
      goodAnswer: false,
      text: value.G ? `question.${i}.answer.4.text` : null,
    }])
      .filter(v => !!v.text)
    
    const levelData = acc[level] ? [...acc[level], {
      question: question,
      id: `id__${i}`,
      answers: answers,
      endedExplanation: endedExplanation,
      explanation: question,
    }] : [{
      id: `id__${i}`,
      question: question,
      answers: answers,
      endedExplanation: endedExplanation,
      explanation: question,
    }]
    return {
      ...acc,
      [level]: levelData
    }
  }, {})
  
  const quizTexts = quiz.reduce((acc, value, i) => {
    if (!value) return
    const level = `lv-${value.B}`
    const question = value.C
    const explanation = value.H
    const answers = {
      [`question.${i}.answer.1.text`]: value.D ?? null,
      [`question.${i}.answer.2.text`]: value.E ?? null,
      [`question.${i}.answer.3.text`]: value.F ?? null,
      [`question.${i}.answer.4.text`]: value.G ?? null
    }
    
    const levelData = {
      [`question.${i}.question`]: question,
      [`question.${i}.endedExplanation`]: explanation,
      [`question.${i}.explanation`]: question,
      [`answers.${i}`]: answers,
    }
    return {
      ...acc,
      [level]: {
        'theme': value.A,
        ...acc[level],
        ...levelData
      }
    }
  }, {})
  return {
    content: quizContent,
    texts: quizTexts
  }
}

export function shuffle (array) {
  return array.sort(() => Math.random() - 0.5)
}
