import React, {useState} from 'react'
import {DuelQuizGame} from './src/Game'

export const DuelQuiz = ({
                           data,
                           onClose,
                           playerProfile,
                           opponentProfile,
                           opponentResult,
                           contentTranslations,
                           lang,
                           quizBoss,
                           translateUrl,
                           botProfile,
                           phase,
                           knowMoreData
                         }) => {
  const [timeStampStart] = useState(new Date())
  return <DuelQuizGame data={data}
                       onClose={onClose}
                       playerProfile={playerProfile}
                       opponentResult={opponentResult}
                       contentTranslations={contentTranslations}
                       lang={lang}
                       quizBoss={quizBoss}
                       timeStampStart={timeStampStart}
                       opponentProfile={opponentProfile}
                       botProfile={botProfile}
                       phase={phase}
                       knowMoreData={knowMoreData}
                       translateUrl={translateUrl}/>
}


