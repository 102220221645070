import {
  campaignGet,
  campaignSet,
} from "../../saga-tools/Data.effects"
import {nanoid} from "nanoid"

export function* teamJoined(event) {
  yield campaignSet(`teams/${event.teamId}`, (oldValues) => ({
    ...oldValues,
    members: Object.values(oldValues.members || []).concat(event.target),
  }))
  yield campaignSet(`states/${event.target}`, (oldValues) => ({
    ...oldValues,
    teamId: event.teamId,
  }))

  yield campaignSet(
    `teams.${event.teamId}.contributions.${event.target}`,
    0
  )
}

export function* teamCreated(event) {
  const points = Number(yield campaignGet(`state/${event.target}/points`)) || 0

  yield campaignSet(`teams/${event.teamId}`, {
    name: event.name,
    members: [event.target],
    points: points,
    captain: event.target,
    logo: {
      shape: event.logoShape,
      color: event.color,
      text: event.name.charAt(0).toUpperCase(),
    },
    contributions: {
      [event.target]: points,
    },
  })
  yield campaignSet(`states/${event.target}`, (oldValues) => ({
    ...oldValues,
    teamId: event.teamId,
    captain: true,
  }))
  yield campaignSet(`leaderboards/teamLeaderboard/${event.teamId}`, () => 0)
}

export function* teamMessageSent(event) {
  const userId = event?.target
  const userName = event?.userName
  const teamId = event?.teamId
  const message = event?.message
  const messageId = nanoid()

  yield campaignSet(`teams/${teamId}/chat`, (oldValues) => ({
    ...oldValues,
    [messageId]: {
      emitter: userId,
      message: message,
      timeStamp: Date.now(),
      userName: userName
    },
  }))
}

export function* lastMessageChatTeamSeen(event) {
  const userId = event?.target
  const messageId = event?.messageId

  yield campaignSet(`states/${userId}/lastMessageSeenChatTeam`, () => messageId)
}
