import { campaignSet, getClientId, publicProfileSet, set } from '../../saga-tools/Data.effects'

export function* playerJoined(event) {
  const phase = event?.phase
  switch (phase) {
    case 1:
      yield campaignSet(`states/${event.target}`, {
        lang: event.lang,
        map: {
          playerPosition: event.mapDefaultPosition,
          fogMap: event.fogMapDefault
        },
        actionPoints: {
          spentActionPoints: 0,
          lastPASpent: ''
        }
      })
      yield campaignSet(`leaderboards/leaderboard/${event.target}`, 0)
      break
    case 1.3:
      const worldsNumber = new Array(event?.campaignSettings?.worldsNumber).fill(0)
      const worldsProperties = event?.worldsProperties
      const worldsTemplate = worldsNumber?.reduce((acc, _e, index) => {
        const worldProperties = worldsProperties?.filter(v => parseInt(v?.id) === index + 1)?.[0]
        if (index === 0) {
          acc[index + 1] = {
            lock: false,
            map: {
              fogMap: JSON.parse(worldProperties?.map?.fogMapDefault) ?
                JSON.parse(worldProperties?.map?.fogMapDefault)
                : 0,
              playerPosition: worldProperties?.map?.mapDefaultPosition || 0,
            }
          }
        } else {
          acc[index + 1] = {
            lock: true,
            map: {
              fogMap: worldProperties?.map?.fogMapDefault ?
                JSON.parse(worldProperties?.map?.fogMapDefault)
                : 0,
              playerPosition: worldProperties?.map?.mapDefaultPosition || 0,
            }
          }
        }
        return acc
      }, {})
      yield campaignSet(`sessions/${event.target}`, {
        initialized: true
      })
      yield campaignSet(`states/${event.target}`, {
        lang: event.lang,
        actionPoints: {
          spentActionPoints: 0,
          lastPASpent: ''
        },
        worlds: worldsTemplate
      })
      yield campaignSet(`leaderboards/leaderboard/${event.target}`, 0)
      break
    case 2:
      yield campaignSet(`states/${event.target}`, {
        lang: event.lang,
        actionPoints: {
          spentActionPoints: 0,
          lastPASpent: ''
        }
      })
      yield campaignSet(`sessions/${event.target}`, (session) => ({
        ...session,
        initialized: true,
        ...event.lockBuildingsFromOrder && { buildingProgression: 1 }
      }))
      yield campaignSet(`leaderboards/leaderboard/${event.target}`, 0)
      break
    default:
      return
  }
}

export function* publicProfileInitiated(event) {
  const userId = event.target
  const user = event.user
  yield publicProfileSet(userId, {name: user.name})
}

export function* userInfosDeleted(event) {
  const userId = event.target
  const teamId = event.teamId
  const phase = event.phase
  const pointsContribution = event.pointsContribution || 0
  if (phase === 2) {
    yield campaignSet(`teams/${teamId}`, (team) => {
      const newTeamPoints = (team?.wonderState?.points - pointsContribution) > 0 ? (team?.wonderState?.points - pointsContribution) : 0
      const newContributors = Object.fromEntries(Object.entries(team.contributions).filter(([id, _points]) => id !== userId)) || null
      const newMembers = team.members.filter((id) => id !== userId) || []

      return {
        ...team,
        wonderState: {
          ...team?.wonderState,
          points: newTeamPoints
        },
        contributions: newContributors,
        members: newMembers
      }
    })
  } else {
    yield campaignSet(`teams/${teamId}`, (team) => {
      const newTeamPoints = (team.points - pointsContribution) > 0 ? (team.points - pointsContribution) : 0
      const newContributors = Object.fromEntries(Object.entries(team.contributions).filter(([id, _points]) => id !== userId)) || null
      const newMembers = team.members.filter((id) => id !== userId) || []

      return {
        ...team,
        points: newTeamPoints,
        contributions: newContributors,
        members: newMembers
      }
    })
  }

  yield campaignSet(`leaderboards/teamLeaderboard/${teamId}`,
    (teamPoints) => teamPoints - pointsContribution)
  yield campaignSet(`leaderboards/leaderboard/${userId}`, null)
  yield campaignSet(`states/${userId}`, null)
  yield campaignSet(`sessions/${userId}`, null)
}

export function* nameProfileUpdated(event) {
  const userId = event.target
  yield publicProfileSet(userId, (v) => ({...v, name: event.profile}))
}

export function* langUpdated(event) {
  const clientId = yield getClientId()
  yield set(`clients/${clientId}/profiles/${event.target}`, v => ({
    ...v,
    lang: event.lang,
  }))
}
/*

export function* browserInfosUpdated(event) {
  yield admin.database()
    .ref(`browserInfos/${event.clientId}/campaigns/${event.campaignId}/${event.target}/${new Date()}`)
    .set(event.browserInfos)
}
*/
