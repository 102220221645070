import {campaignSet} from "../../../../saga-tools/Data.effects"

export function* phase2BuildingEvolved(event) {
  const buildingType = event?.buildingType
  const buildingIsWonder = buildingType === 'merveille'
  const teamId = event?.teamId
  const playerFullOnBoarding = event?.playerFullOnBoarding
  if (buildingIsWonder) {
    if (!playerFullOnBoarding) {
      yield campaignSet(`teams/${teamId}/wonderState/step`,
        (step) => step ? step + 1 : 2)
    }else {
      yield campaignSet(`states/${event.target}/onBoardingGameState/wonderState/step`,
        (step) => step ? step + 1 : 2)
    }
  } else {
    if (!playerFullOnBoarding) {
      yield campaignSet(`teams/${teamId}/buildingsState/${buildingType}/step`,
        (step) => step ? step + 1 : 2)
    }else {
      yield campaignSet(`states/${event.target}/onBoardingGameState/buildingsState/${buildingType}/step`,
        (step) => step ? step + 1 : 2)
    }
  }
}
