import React          from 'react'
import { View }       from 'react-native'
import styled         from '@emotion/native'
import { useHovered } from '../../hooks/useHovered'
import { colors }     from '../../configs/colors'

export const DefaultCard = ({ children }) => (
  <DefaultCardWrapper>{children}</DefaultCardWrapper>
)

export const PressedInCard = ({
  children,
  soft,
  noHover,
  hoverColor,
  size,
  customPadding,
  isActive,
  activeBackground,
  noCursor,
  ...props
}) => {
  const [hovered, hoverProps] = useHovered()

  return (
    <PressedInCardWrapper
      {...{soft, noHover, customPadding, activeBackground}}
      isActive={isActive || false}
      noCursor={noCursor}
      hover={!noHover ? hovered : false}
      hoverColor={hoverColor || colors.defaultBackground}
      {...hoverProps}
      {...props}
    >
      {children}
    </PressedInCardWrapper>
  )
}

export const RectangleCard = ({children}) => (
  <RectanglePlateWrapper>{children}</RectanglePlateWrapper>
)

export const BubbleCard = ({children}) => (
  <View>
    <DefaultCardWrapper>{children}</DefaultCardWrapper>
    <Triangle/>
  </View>
)

export const ProgressBar = ({total, filled}) => {
  const ProgressItems = [...Array(total)].map((e, i) => {
    return (
      <ProgressItem
        key={i}
        filled={i <= filled}
        last={i === total - 1}
        first={i === 0}
        total={total}
      />
    )
  })
  return <ProgressBarWrapper>{ProgressItems}</ProgressBarWrapper>
}

const DefaultCardWrapper = styled.View({
  backgroundColor: colors.defaultBackground,
  borderRadius: 5,
  paddingTop: 30,
  paddingBottom: 30,
  paddingLeft: 30,
  paddingRight: 30,
  justifyContent: "center",
  alignItems: "center",
})

const PressedInCardWrapper = styled(DefaultCardWrapper)(
  ({
    hover,
    noHover,
    soft,
    hoverColor,
    size,
    customPadding,
    isActive,
    activeBackground,
    noCursor,
  }) => ({
    width: size ? size : "auto",
    height: size ? size : "auto",
    paddingTop: customPadding ? customPadding : 30,
    paddingBottom: customPadding ? customPadding : 30,
    paddingLeft: customPadding ? customPadding : 30,
    paddingRight: customPadding ? customPadding : 30,
    cursor: noHover || noCursor ? "unset" : "pointer",
    backgroundColor: isActive
      ? activeBackground
      : hover
        ? hoverColor
        : colors.defaultBackground,
    ...(isActive
      ? {
        boxShadow:
          "inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)",
      }
      : hover
        ? soft
          ? {
            boxShadow:
              "1px 1px 3px rgba(94, 104, 121, 0.945), -1px -1px 4px #FFFFFF",
          }
          : {
            boxShadow:
              "3px 3px 5px rgba(94, 104, 121, 0.945), -3px -3px 7px #FFFFFF",
          }
        : soft
          ? {
            boxShadow:
              "inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)",
          }
          : {
            boxShadow:
              "inset -3px -3px 7px #FFFFFF, inset 3px 3px 5px rgba(94, 104, 121, 0.692)",
          }),
  })
)

const Triangle = styled.View({
  width: 0,
  height: 0,
  borderColor: "transparent",
  borderTopColor: colors.defaultBackground,
  borderTopWidth: 14,
  borderLeftWidth: 14,
  borderRightWidth: 14,
  alignSelf: "center",
})

const RectanglePlateWrapper = styled(DefaultCardWrapper)({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  height: 27,
  justifyContent: "center",
  alignItems: "center",
})

const ProgressItem = styled.View(
  {
    height: 30,
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderRightColor: colors.defaultBackground,
    borderLeftColor: colors.defaultBackground,
    width: "20%",
    boxShadow:
      "inset -1px -1px 4px #FFFFFF, inset 1px 1px 3px rgba(94, 104, 121, 0.692)",
  },
  ({filled, first, last, total}) => ({
    ...(filled
      ? {
        backgroundColor: "#4C4A4C",
      }
      : {
        backgroundColor: colors.defaultBackground,
      }),
    ...(first && {
      borderBottomLeftRadius: 10,
      borderTopLeftRadius: 10,
      borderLeftWidth: 0,
    }),
    ...(last && {
      borderBottomRightRadius: 10,
      borderTopRightRadius: 10,
      borderRightWidth: 0,
    }),
    ...(total && {
      width: 100 / total + "%",
    }),
  })
)

const ProgressBarWrapper = styled.View({
  width: "80%",
  backgroundColor: colors.defaultBackground,
  borderRadius: 10,
  flexDirection: "row",
})

export const AuthCard = ({ children, mobile }) => {

  return (
    <AuthCardWrapper mobile={mobile}>
      {children}
    </AuthCardWrapper>
  );
};

const AuthCardWrapper = styled.View(({mobile}) => ({
  boxShadow: "3px 3px 5px rgba(94, 104, 121, 0.945), -3px -3px 7px #FFFFFF",
  borderRadius: 9,
  maxWidth: 700,
  width: mobile ? "95%" : "100%",
  flexDirection: "column",
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: [{translateX: '-50%'}, {translateY: '-50%'}]
}))

export const AuthContent = styled.View({
  flexDirection: "column",
  paddingHorizontal: 20,
  paddingTop: 30,
  paddingBottom: 60,
})

export const AuthFooter = styled.View({
  backgroundColor: colors.defaultBackground,
  borderBottomLeftRadius: 9,
  borderBottomRightRadius: 9,
  paddingVertical: 17,
})
