import { campaignSet } from '../../../../saga-tools/Data.effects'

export function * phase2PointsWin (event) {

}

export function * phase2FlouzWin (event) {
  const flouz = event?.flouz
  yield campaignSet(`states/${event.target}`,
    (state) => ({
      ...state,
      flouz: state.flouz ? state.flouz + flouz : flouz,
      points: state.points ? state.points + flouz : flouz
    }))
  
  yield campaignSet(`leaderboards/leaderboard/${event.target}`,
    (userPoints) => userPoints ? userPoints + flouz : flouz)
}

export function * leaderboardSynchronized(event) {
  yield campaignSet(`leaderboards/leaderboard/${event.target}`,
    (userPoints) => userPoints)
}

export function * phase2BuildingInvested (event) {
  const investValue = event?.investValue
  const userId = event.target

    yield campaignSet(`states/${userId}`,
      (userState) => ({
        ...userState,
        flouz: userState?.flouz ? userState.flouz - investValue : 0,
        onBoardingGameState: {
          ...userState?.onBoardingGameState,
          wonderState: {
            ...userState?.onBoardingGameState.wonderState,
            points: (userState?.onBoardingGameState.wonderState?.points || 0) + investValue,
            step: event?.evolve ? userState?.onBoardingGameState.wonderState?.step ? userState?.onBoardingGameState.wonderState?.step + 1
                : 2
              : userState?.onBoardingGameState.wonderState?.step ?? 1
          }
        }
      }))
}
