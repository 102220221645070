type DocumentStructure = {
  [key in string]?: any
}

const documentType = 'document'

type DocumentDescriptor<DocumentType extends DocumentStructure> = {
  type: typeof documentType
  siblings: SiblingCollections
}

type SiblingCollections = {
  [key in string]:
    | CollectionDescriptor<string, any>
    | StorageDescriptor<string, any>
}
const document = <DocumentType extends DocumentStructure>(
  siblings?: SiblingCollections
): DocumentDescriptor<DocumentType> => {
  return {
    type: documentType,
    siblings
  }
}

export const collectionType = 'collection'
type CollectionDescriptor<
  KeyType extends string,
  DocumentType extends DocumentStructure
> = {
  type: typeof collectionType
  siblings: SiblingCollections
}
export const collection = <
  KeyType extends string,
  DocumentType extends DocumentStructure
>(
  siblings?: SiblingCollections
): CollectionDescriptor<KeyType, DocumentType> => {
  return {
    type: collectionType,
    siblings
  }
}

export const collectionGroupType = 'collectionGroup'
type CollectionGroupDescriptor<
  KeyType extends string,
  DocumentType extends DocumentStructure
> = {
  type: typeof collectionGroupType
  siblings: SiblingCollections
}
export const collectionGroup = <
  KeyType extends string,
  DocumentType extends DocumentStructure
>(
  collectionDescriptor: CollectionDescriptor<KeyType, DocumentType>
): CollectionGroupDescriptor<KeyType, DocumentType> => {
  return {
    type: collectionGroupType,
    siblings: collectionDescriptor.siblings
  }
}

export const storageType = 'storage'
type StorageDescriptor<
  KeyType extends string,
  DocumentType extends DocumentStructure
> = {
  type: typeof storageType
  siblings: SiblingCollections
  storageKey: string
}
type StorageSiblingCollections = {
  [key in string]: StorageDescriptor<string, any>
}
export const storage = <
  KeyType extends string,
  DocumentType extends DocumentStructure
>(
  storageKey: string,
  siblings?: StorageSiblingCollections
): StorageDescriptor<KeyType, DocumentType> => {
  return {
    type: storageType,
    siblings,
    storageKey
  }
}

export type DatabaseDescriptor = {
  [key: string]:
    | CollectionDescriptor<string, any>
    | CollectionGroupDescriptor<string, any>
    | StorageDescriptor<string, any>
}
export const database = (
  descriptor: DatabaseDescriptor
): DatabaseDescriptor => {
  return descriptor
}

export const pathType = 'path'
type PathNode = {
  type: string
  path: string
  storageKey?: string
}

export const splitPathByType = (
  path: string,
  database: DatabaseDescriptor
): PathNode[] => {
  const checkPath = path?.split('/')
  const isAFilePath =
    checkPath.length > 1 &&
    checkPath.filter(e => e.includes('.')).length === 1 &&
    checkPath[checkPath.length - 1].includes('.')
  const pathKeys = isAFilePath
    ? path?.split(/[\/]/)
    : path?.match(/[\/.]/)
    ? path.split(/[\/.]/)
    : [path]
  const firstKey = pathKeys.shift()
  let previousPath: any = database[firstKey]
  if (!previousPath) throw new Error('No databases definition for : ' + firstKey)

  const firstValue: PathNode = { type: previousPath.type, path: firstKey }
  if (previousPath.type === storageType) {
    firstValue.storageKey = previousPath.storageKey
  }
  const firstNode: PathNode[] = [firstValue]
  return firstNode.concat(
    pathKeys.map(key => {
      const sibling = previousPath?.siblings?.[key]
      const nextPath = !!sibling
        ? { type: sibling.type, path: key, storageKey: sibling.storageKey }
        : { path: key, type: pathType, storageKey: null }
      if (sibling?.type !== storageType) {
        delete nextPath.storageKey
      }
      if (sibling) {
        previousPath = sibling
      }
      return nextPath
    })
  )
}

export const formatPathForAccessor = (
  path: string,
  database: DatabaseDescriptor
): PathNode[] => {
  const pathTypes = splitPathByType(path, database)
  const reversedLastIndexStorage = [...pathTypes]
    .reverse()
    .findIndex(v => v.type === 'storage')
  if (reversedLastIndexStorage !== -1) {
    const lastStorageIndex = pathTypes.length - (reversedLastIndexStorage + 1)
    const storagePath = getPath(pathTypes.slice(0, lastStorageIndex + 1))
    const storageNode = {
      type: storageType,
      path: storagePath,
      storageKey: pathTypes[lastStorageIndex].storageKey
    }
    pathTypes.splice(0, lastStorageIndex + 1, storageNode)
  }
  const firstPathIndex = pathTypes.findIndex(
    (v, i) => v.type === pathType && pathTypes[i + 1]?.type === pathType
  )
  if (firstPathIndex === -1) {
    return pathTypes
  }
  const documentPath = getPath(pathTypes.slice(firstPathIndex))
  const documentNode = {
    type: pathType,
    path: documentPath
  }
  pathTypes.splice(firstPathIndex, pathTypes.length, documentNode)
  return pathTypes
}

const getPath = v => v.map(pathType => pathType.path).join('/')
