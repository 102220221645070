import React, {useEffect, useState} from 'react'
import styled                       from "@emotion/native"
import {Animated}                   from "react-native"
import {colors}                     from "@civitime/library/storybook/configs/colors"
import {AvatarComponent}            from "@civitime/library/storybook/stories/Avatar"
import { useTranslator }            from '../translations/translate'

export const ResultsScreen = ({screenDimensions, mobile, result, playerProfile, opponentProfile, onClose, data, opponentResult, questionsTimeStamps, botProfile, timeStampStart, quizBoss}) => {
  const [thunderScale] = useState(new Animated.Value(10))
  const {t} = useTranslator()
  useEffect(() => {
    Animated.timing(thunderScale, {toValue: 1, duration: 1500, useNativeDriver: true}).start()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      onClose()
    }, 2500)
  }, [])

  return <WrapperResultScreen screenDimensions={screenDimensions}
                              mobile={mobile}>
    <WrapperResultsScreen screenDimensions={screenDimensions}
                          mobile={mobile}>
      <ResultsScreenHeader>
        <ResultsScreenHeaderText>
          {t('app.results.title')}
        </ResultsScreenHeaderText>
      </ResultsScreenHeader>
      <WrapperResultsScreenContent>
        {!quizBoss && <ResultsScreenMessage>{
          Object.values(result).every((res) => res) ?
            t('app.results.excellent') :
            Object.values(result).some((res) => res) ?
              t('app.results.message') :
              ''
        }</ResultsScreenMessage>}
        <ResultsScreenWrapper>
          <ResultPlayerWrapper mobile={mobile}>
            <PlayerWrapper>
              <AvatarComponent circleAvatarConfig={{
                size: mobile ? 60 : 80,
                withBorder: true,
                backgroundColor: colors.extraLightBackground,
              }}
                               avatar={playerProfile?.avatar}/>
              <PlayerName mobile={mobile}>{playerProfile?.name}</PlayerName>

            </PlayerWrapper>
            <PlayerResult mobile={mobile}>
              {
                Object.values(result || {})?.filter((e) => e)?.length
              }
            </PlayerResult>
          </ResultPlayerWrapper>
          <ThunderResult source={require('../Assets/thunder-orange.png')}
                         alt='thunder result'
                         style={{transform: [{scale: thunderScale}]}}/>
          <ResultPlayerWrapper mobile={mobile} opponent>
            {
              (opponentResult || opponentResult === 0) ? <OpponentPlayerResult mobile={mobile}>
                {opponentResult}
              </OpponentPlayerResult>
                : <ResultOpponent mobile={mobile}>
                  <ResultPlayerText mobile={mobile}>
                    {t('app.results.awaiting')}
                  </ResultPlayerText>
                </ResultOpponent>
            }
            <OpponentWrapper>
              <AvatarComponent circleAvatarConfig={{
                size: mobile ? 60 : 80,
                withBorder: true,
                backgroundColor: colors.extraLightBackground,
              }}
                               avatar={opponentProfile?.avatar || botProfile?.avatar}/>
              <PlayerName mobile={mobile}>{opponentProfile?.name}</PlayerName>
            </OpponentWrapper>
          </ResultPlayerWrapper>
        </ResultsScreenWrapper>
      </WrapperResultsScreenContent>
    </WrapperResultsScreen>
  </WrapperResultScreen>
}

const ThunderResult = styled(Animated.Image)({
  position: 'absolute',
  width: 43,
  height: 96,
  zIndex: 2,
})

const ResultPlayerText = styled.Text(({mobile}) => ({
  color: 'white',
  textTransform: 'uppercase',
  fontSize: mobile ? 12 : 16,
  margin: 'auto'
}))

const ResultOpponent = styled.View(({mobile}) => ({
  backgroundColor: colors.module,
  borderRadius: 18,
  height: mobile ? 17 : 23,
  paddingLeft: 15,
  paddingRight: 15,
  marginTop: mobile ? 8 : 32,
  marginBottom: mobile ? 40 : 0,
  marginRight: mobile ? 0 : 20
}))

const ResultPlayerWrapper = styled.View(({mobile, opponent}) => ({
  flexDirection: mobile && opponent ? 'column-reverse' : mobile ? 'column' : 'row',
  alignItems: mobile ? 'center' : 'unset',
  zIndex: 1,
}))

const PlayerName = styled.Text(({mobile}) => ({
  color: colors.dropShadow,
  fontWeight: 'bold',
  fontSize: mobile ? 12 : 16,
  marginTop: 20,
  marginBottom: 20
}))

const WrapperResultScreen = styled(Animated.View)(({screenDimensions, mobile}) => ({
  position: 'absolute',
  flexDirection: 'row',
  top: mobile ? 'unset' : (screenDimensions.height - (screenDimensions.height * 0.66)) / 2,
  bottom: mobile ? 0 : 'unset',
  width: (screenDimensions.width),
  height: mobile ? screenDimensions.height * 0.90 : screenDimensions.height * 0.66,
}))

const WrapperResultsScreen = styled.View(({screenDimensions, mobile}) => ({
  width: mobile ? screenDimensions.width * 0.90 : screenDimensions.width * 0.66,
  height: mobile ? screenDimensions.height * 0.80 : screenDimensions.height * 0.66,
  maxWidth: 840,
  maxHeight: 480,
  left: mobile ?
    ((screenDimensions.width - (screenDimensions.width * 0.90)) / 2) :
    ((screenDimensions.width - (screenDimensions.width * 0.66 <= 840 ? screenDimensions.width * 0.66 : 840)) / 2)
}))

const ResultsScreenHeader = styled.View({
  height: 33,
  backgroundColor: colors.dropShadow,
  borderTopRightRadius: 9,
  borderTopLeftRadius: 9,
  justifyContent: 'center',
  alignItems: 'center'
})

const ResultsScreenHeaderText = styled.Text({
  textTransform: 'uppercase',
  fontSize: 15,
  color: colors.lightBackground
})

const WrapperResultsScreenContent = styled.View({
  backgroundColor: 'white',
  borderRightColor: colors.dropShadow,
  borderLeftColor: colors.dropShadow,
  borderTopColor: 'transparent',
  borderBottomColor: colors.dropShadow,
  borderWidth: 1,
})

const ResultsScreenMessage = styled.Text({
  textTransform: 'uppercase',
  fontSize: 18,
  color: colors.dropShadow,
  margin: 'auto',
  marginTop: 20,
  marginBottom: 20,
  fontWeight: 'bold',
  textAlign: 'center'
})

const ResultsScreenMessageWrapper = styled.View({
  alignItems: 'center',
})

const ResultsScreenWrapper = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-around'
})

const PlayerWrapper = styled.View({
  alignItems: 'center'
})

const OpponentWrapper = styled.View({
  alignItems: 'center'
})

const PlayerResult = styled.Text(({mobile}) => ({
  fontSize: mobile ? 22 : 35,
  color: colors.module,
  fontWeight: 'bold',
  marginTop: mobile ? 0 : 20,
  marginLeft: mobile ? 0 : 50
}))

const OpponentPlayerResult = styled.Text(({mobile}) => ({
  fontSize: mobile ? 22 : 35,
  color: colors.module,
  fontWeight: 'bold',
  marginTop: mobile ? 0 : 20,
  marginRight: mobile ? 0 : 50,
  alignSelf: 'right'
}))
