import { T }                                   from '../translations/translate'
import React, { useEffect, useRef, useState }  from 'react'
import { InGameModuleScreen } from '@civitime/library/storybook/stories/Modules'
import styled                    from '@emotion/native'
import { Animated, View } from 'react-native'
import { Video }                 from 'expo-av'

const didYouKnowinfos = (data) => ({
  title: <T path={'app.didYouKnow.title'}/>,
  objectives: {
    title: <T path={'app.didYouKnow.objectivesTitle'}/>,
    text: <T path={data?.[0]?.objective}/>
  },
  content: {
    title: <T path={'app.didYouKnow.contentTitle'}/>,
    text: <T path={data?.[0]?.intro}/>
  },
  type: 'didYouKnow'
})

const memoTimeInfos = (data) => {
  return ({
    title: <T path={'app.memoTime.title'}/>,
    objectives: {
      text: <T path={data?.[0]?.objective}/>
    },
    content: {
      text: <T path={data?.[0]?.outro}/>
    },
    type: 'memoTime'
  })
}

export const Intro = ({ height, onPress, data, quit, isSafari }) => {
  const topDidYouKnowAnim = useRef(new Animated.Value(height)).current
  Animated.timing(
    topDidYouKnowAnim,
    {
      toValue: 0,
      duration: 800,
      useNativeDriver: true
    }
  )
    .start()
  
  const quitIntroScreen = () => {
    Animated.timing(
      topDidYouKnowAnim,
      {
        toValue: height,
        duration: 800,
        useNativeDriver: true
      }
    ).start(onPress)
  }
  return <Wrapper height={height}>
    <DidYouKnowWrapper top={topDidYouKnowAnim}>
      <DidYouKnow onPress={quitIntroScreen} data={data} quit={quit} isSafari={isSafari}/>
    </DidYouKnowWrapper>
  </Wrapper>
}

export const Outro = ({ height, onPress, data, knowMoreData, mobile, setHideModuleContentOnQuit, isSafari }) => {
  const topDidYouKnowAnim = useRef(new Animated.Value(height)).current
  
  Animated.timing(
    topDidYouKnowAnim,
    {
      toValue: 0,
      duration: 800,
      useNativeDriver: true
    }
  )
    .start()
  
  const quitIntroScreen = () => {
    setHideModuleContentOnQuit(true)
    Animated.timing(
      topDidYouKnowAnim,
      {
        toValue: height,
        duration: 800,
        delay: 200,
        useNativeDriver: true
      }
    ).start(onPress)
  }
  return <Wrapper height={height}>
    <DidYouKnowWrapper top={topDidYouKnowAnim}>
      <MemoTime onPress={quitIntroScreen} data={data} knowMoreData={knowMoreData} height={height} mobile={mobile} isSafari={isSafari}/>
    </DidYouKnowWrapper>
  </Wrapper>
}

export const DidYouKnow = ({ onPress, data, quit, isSafari }) => {
  return <InGameModuleScreen infos={didYouKnowinfos(data)} onPress={onPress} data={data} buttonAction={onPress} returnAction={quit} isSafari={isSafari}/>
}

export const MemoTime = ({ onPress, data, knowMoreData, isSafari }) => {
  const [asset, setAsset] = useState(undefined)
  const [showDetail, setShowDetail] = useState(false)
  
  const getAsset = async () => {
    const blob = await fetch(knowMoreData?.path)
      .then(res => res.blob())
    const newBlob = new Blob([blob], { type: getFileType(knowMoreData?.type) })
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob)
      return
    }
    const frontUrl = window.URL.createObjectURL(newBlob)
    setAsset(frontUrl)
  }
  
  const buttonAction = async () => {
    if (knowMoreData?.type === 'pdf') {
      window.open(asset,
        '_blank')
      onPress()
      return
    } else {
      return showDetail ? onPress() : setShowDetail(true)
    }
  }

  useEffect(() => {
    getAsset()
  }, [knowMoreData])
  
  return <InGameModuleScreen infos={memoTimeInfos(data)} showDetail={showDetail} returnAction={onPress}
                             onPress={onPress} isMemo data={data} buttonAction={buttonAction} secondAction={onPress}
                             knowMoreData={knowMoreData} isSafari={isSafari}>
  
    {
      showDetail && knowMoreData?.type === 'video' && <View style={{ zIndex: 100, height: '100%' }}>
        <Video
          style={{ width: '100%', height: '50%' }}
          source={{
            uri: asset,
          }}
          useNativeControls
          resizeMode="contain"
          isLooping
        />
      </View>
    }
  </InGameModuleScreen>
}

const Wrapper = styled.View(({ height }) => ({
  width: '100%',
  height: height,
  overflow: 'hidden',
  position: 'relative',
  zIndex: 20,
}))

const DidYouKnowWrapper = styled(Animated.View)(({ top }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: top,
  height: '100%'
}))

const getFileType = (type) => {
  switch (type) {
    case 'pdf':
      return 'application/pdf'
    case 'video':
      return 'application/mp4'
  }
}
