import React, { useRef } from "react"
import { View } from "react-native"
import styled from "@emotion/native"
import { asRelative } from "../../configs/sizes"
import { colors } from "../../configs/colors"
import { CloseButton } from "../Button"
import { useScreenDimensions } from "../../hooks/useScreenDimensions"
import { MOBILE_HEADER_SIZE } from "../../configs/sizes"

export const RawDialog = ({
  children,
  closable,
  onRequestClose,
  noBackgroundShadow,
  ...props
}) => {
  const root = useRef(null)
  const { mobile, width, height, orientation } = useScreenDimensions(root)

  return (
    <BackDrop ref={root} {...{ noBackgroundShadow, mobile }}>
      <DialogWrapper
        {...{
          mobile,
          width,
          height,
          orientation,
          closable,
          noBackgroundShadow,
          ...props
        }}
      >
        {closable && (
          <View
            style={{
              position: "absolute",
              alignItems: "flex-end",
              top: 10,
              right: 10,
            }}
          >
            <CloseButton
              onPress={onRequestClose}
              color={colors.secondary}
              size={20}
              iconColor={"white"}
              iconSize={"50%"}
            />
          </View>
        )}
        {children}
      </DialogWrapper>
    </BackDrop>
  )
}

const DialogWrapper = styled.View(
  {
    overflow: "hidden",
    backgroundColor: colors.defaultBackground,
    borderRadius: 5,
  },
  ({ closable, mobile, width, height, orientation, noBackgroundShadow }) => ({
    padding: mobile ? 20 : 40,
    maxWidth: mobile ? width * 0.95 : asRelative(454),
    paddingVertical: closable ? (mobile ? 35 : 50) : 30,
    boxShadow: noBackgroundShadow
      ? "-3px -3px 7px #FFFFFF, 3px 3px 5px rgba(94, 104, 121, 0.945)"
      : "inset -3px -3px 7px #FFFFFF, inset 3px 3px 5px rgba(94, 104, 121, 0.692)",
    ...(mobile &&
      orientation === "portrait" && {
        minWidth: width * 0.95,
      }),
    ...(mobile && {
      maxHeight: height - (MOBILE_HEADER_SIZE + 10),
    }),
  })
)
const BackDrop = styled.View(
  {
    flex: 1,
    alignItems: "center",
  },
  ({ noBackgroundShadow }) =>
    !noBackgroundShadow && {
      backgroundColor: "rgba(74, 74, 74, 0.8)",
    },
  ({ mobile }) =>
    mobile
      ? {
          paddingTop: MOBILE_HEADER_SIZE + 5,
        }
      : {
          justifyContent: "center",
        }
)
