import { campaignSet } from '../../../../saga-tools/Data.effects'

export function * campaignEndedModalSeen (event) {
  yield campaignSet(`states/${event.target}/campaignEndedModalSeen`,
    true
  )
}

export function * gameAccountReInitialized (event) {
  const userId = event.target
  yield campaignSet(`states/${userId}`,
    () => ({
      id: "userId",
      actionPoints: {
        spentActionPoints: 0,
        lastPASpent: ''
      },
    }))
  yield campaignSet(`leaderboards/leaderboard/${userId}`, null)
  yield campaignSet(`sessions/${userId}`, { initialized: true, id: userId, buildingProgression: 1 })
}
