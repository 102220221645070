import {call, getContext} from "redux-saga/effects"
import {nanoid} from "nanoid"

export function obtainLock(id) {
  return call(obtainLockSaga, id)
}

export const lockSeparator = "::"

function* obtainLockSaga(id) {
  const transactionId = new Date().toISOString() + lockSeparator + nanoid()
  const addLock = yield getContext("addLock")
  const getDatabaseLock = yield getContext('getDatabaseLock')

  const result = yield getDatabaseLock(id, transactionId)

  if (!result) throw new Error("Unable to obtain a lock on " + id)

  addLock(id, transactionId)
}

export function freeLock(id) {
  return call(freeLockSaga, id)
}

function* freeLockSaga(id) {
  const freeLock = yield getContext("freeLock")
  const getLock = yield getContext("getCurrentLock")
  const transaction = getLock(id)

  freeLock(id, transaction)
}
