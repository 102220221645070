import React from 'react'

export const UseLinkInText = (text) => {
  const rawText = text?.text ? text?.text?.toString()?.split(' ') : []
  const value = rawText.map((v, i) => {
    if (typeof v === 'object') {
      return <span key={i}>{v}</span>
    }
    if (v.trim() === '\\n') {
      return <br />
    }
    if (typeof v === 'string') {
      return v
        .replace(/<a[^>]*href=["']?([^"' >]+)['"]?[^>]*>[^<]*<\/a>/gi, '$1')
        .split(' ')
        .map(v => {
          if (v === '\\n' ){
            return '\n'
          }
          else return v
        })
        .join(' ')
        .split(/(?=http)| /)
        .map((item) => {
          if (item.startsWith('http')) {
            return (
              <a
                href={item}
                target='_blank'
                rel='noopener noreferrer'
                onClick={(e) => e.stopPropagation()}
              >
                lien
              </a>
            )
          } else {
            return item + ' '
          }
        })
    }
  })
  
  return <React.Fragment>{value}</React.Fragment>
}
