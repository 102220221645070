import { stdChannel, runSaga } from 'redux-saga'
import { Subject } from 'rxjs'
import { dataMiddleware } from './Data.effects'

export const SAGA_ENDED = 'SAGA_ENDED'
export function createSagaRawRunner (saga, accessor) {
  const inChannel = stdChannel()
  const outStream$ = new Subject()

  const IO = {
    channel: inChannel,
    getState () {
      throw new Error("getState in saga runner shouldn't be called.")
    },
    dispatch (event) {
      outStream$.next(event)
    },
    effectMiddlewares: [dataMiddleware(accessor)]
  }

  const task = runSaga(IO, saga)

  return {
    events$: outStream$,
    emit (command) {
      inChannel.put(command)
    },
    close () {
      inChannel.close()
      outStream$.complete()
      task.cancel()
    }
  }
}
