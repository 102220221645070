import {campaignGet, sendEvent} from "../../../../saga-tools/Data.effects"
import {createFunctionalError} from "../../../../saga-tools/Errors"
import {profileErrors} from "../../../Profile/Profile.errors"

export function* phase2UpdateBuildingSeen(command) {
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)

  yield sendEvent('Phase2BuildingSeenUpdated', {
    step: command?.step,
    buildingType: command?.buildingType,
  })
}

export function* phase2SeeWonderNotification(_command) {
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)

  yield sendEvent('Phase2WonderNotificationSeen', {})
}

export function* phase2SeeCampaignEndedModal(_command) {
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)

  yield sendEvent('Phase2CampaignEndedModalSeen', {})
}
