import {campaignGet, sendEvent, stateGet} from "../../../../saga-tools/Data.effects"
import {createFunctionalError}            from "../../../../saga-tools/Errors"
import {profileErrors}   from "../../../Profile/Profile.errors"
import { pointsUpdated } from '../Points/Points.reducers'

const DEFAULT_END_POINTS = 500

export function* updatePlayerFragments(command){
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)
  const userState = yield stateGet(command.emitter)
  if(userState){
    yield sendEvent("PlayerFragmentsUpdated", {})
  }
}

export function* updatePlayerThemeFragments(command){
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)
  const userState = yield stateGet(command.emitter)
  if (userState){
    yield sendEvent("PlayerFragmentThemeUpdated", {
      theme: command.theme
    })
  }
}

export function* discoverFinalDestination(command){
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)
  const userState = yield stateGet(command.emitter)
  if(userState){
    yield sendEvent("FinalDestinationDiscovered", {})
  }
}

export function* seeFinalDestinationModal(command){
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)
  const userState = yield stateGet(command.emitter)
  if(userState){
    yield sendEvent("FinalDestinationModalSeen", {})
    const actionPointsSpent = userState?.actionPoints?.spentActionPoints
    const noEndPointsRatio = campaign?.settings?.noEndPointsRatio
    if (noEndPointsRatio){
      const endPoints = campaign?.settings?.endPoints || DEFAULT_END_POINTS
      yield pointsUpdated({
        points: endPoints,
        origin: 'FinishedGame',
        challengeId: command.challengeId,
        target: command.emitter,
        campaignId: command.campaignId,
        clientId: command.clientId,
      })
      return
    }
    const points = actionPointsSpent >= 60 ? 100 : (60 - actionPointsSpent) * 100
    yield pointsUpdated({
      points: points,
      origin: 'FinishedGame',
      challengeId: command.challengeId,
      target: command.emitter,
      campaignId: command.campaignId,
      clientId: command.clientId,
    })
  }
}


