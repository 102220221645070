import { createSagaRawRunner, SAGA_ENDED } from './SagaRunner'
import { createSafeSagaLoop } from './safeSagaLoop'
import { createReducerRawRunner, REDUCER_ENDED } from './ReducerRunner'

export function createReducerRunner (reducers, accessor) {
  return createReducerRawRunner(
    createSafeSagaLoop(reducers, REDUCER_ENDED),
    accessor
  )
}

export function createSagaRunner (sagas, accessor) {
  return createSagaRawRunner(createSafeSagaLoop(sagas, SAGA_ENDED), accessor)
}
