import {
  collectionGroupType,
  collectionType,
  formatPathForAccessor,
  pathType,
  storageType,
}                         from '../databases/DatabaseModel'
import { phase1Database } from '../databases/Phases/phase1Database'
import { getIn, setIn }   from 'icepick'
import { Observable }     from 'rxjs'

let initializedAccessor = null

export function createFirebaseGenericAccessor (app, dbModel = phase1Database, clientSide) {
  const offline = {
    state: false
  }
  const getOptions = (offline) => ({
    source: offline.state ? 'cache' : 'server',
    includeMetadataChanges: true
  })
  const db = app.firestore()
  if (clientSide) {
    db.enablePersistence({synchronizeTabs: true})
  }
  if (initializedAccessor) {
    return initializedAccessor
  }
  return {
    async disableNetwork () {
      offline.state = true
      await db.disableNetwork()
    },
    async enableNetwork (){
      offline.state = false
      await db.enableNetwork()
    },
    async get (rawPath) {
      if (offline.state) {
        await db.disableNetwork()
      }
      const pathNodes = formatPathForAccessor(rawPath, dbModel)
      const [{ type, path, storageKey }] = pathNodes
      
      try {
        if (type === collectionType || type === collectionGroupType) {
          const { ref, fieldPath } = calculate(pathNodes, db, true)
          if (fieldPath) {
            const paths = fieldPath.split('.')
            
            return await ref.get(getOptions(offline)).then((v) => {
              const source = v.metadata.fromCache ? 'local cache' : 'server'
              
              return paths.reduce((acc, path) => {
                return acc[path]
              }, (v.data()))
            })
          }
          
          return await ref.get(getOptions(offline)).then((v) => {
            const source = v.metadata.fromCache ? 'local cache' : 'server'
            if (v.data) return v.data()
            const children = []
            v.forEach((child) => {
              children.push(child.data())
            })
            return children
          })
        }
        if (type === storageType) {
          const [file, ...properties] = pathNodes[1].path.split('/')
          
          const fileProperties =
            properties && properties.length ? `/${properties.join('/')}` : ''
          const fileCompletePath = file + fileProperties
          return await getRaw(app.storage(storageKey), storageKey, fileCompletePath, clientSide)
        }
      } catch (e) {
        console.warn(e)
        return null
      }
    },
    getSync (rawPath) {
      const pathNodes = formatPathForAccessor(rawPath, dbModel)
      const [{ type }] = pathNodes
      try {
        if (type === collectionType || type === collectionGroupType) {
          const { ref, fieldPath } = calculate(pathNodes, db)
          return new Observable(function sub (subscriber) {
            try {
              if (fieldPath) {
                const paths = fieldPath.split('.')
                const unsubCb = ref.onSnapshot(async (v) => {
                  const res = await v.data()
                  const newValue = paths.reduce((acc, path) => {
                    return acc[path]
                  }, res)
                  return subscriber.next(newValue)
                })
                subscriber.add(() => unsubCb())
              } else {
                const unsubCb = ref.onSnapshot((v) => {
                  if (v.data) return subscriber.next(v.data())
                  const children = []
                  v.forEach((child) => {
                    children.push(child.data())
                  })
                  subscriber.next(children)
                })
                subscriber.add(() => unsubCb())
              }
            } catch (e) {
              console.error(e)
            }
          })
        }
        if (type === storageType) {
          throw 'can\'t get sync on storage'
        }
      } catch (e) {
        console.warn(e)
        return null
      }
    },
    async set (rawPath, value) {
      const pathNodes = formatPathForAccessor(rawPath, dbModel)
      const [{ type, path, storageKey }] = pathNodes
      try {
        if (type === collectionType || type === collectionGroupType) {
          const { ref, fieldPath } = calculate(pathNodes, db, true)
          if (typeof value === 'function') {
            const fieldPathArray = fieldPath.split('.')
            let prevData = null
            ref.get(fieldPathArray).then(async prev => {
              const rawData = prev.data()
              prevData = rawData
              const newValue = fieldPath ? setIn(rawData, fieldPathArray, value(getIn(rawData, fieldPathArray))) : value(rawData)
              newValue && !fieldPath && (newValue.id = ref.id)
              await ref.set(newValue)
              return
            })
              .catch(async _ => {
                const newValue = fieldPath ? setIn(prevData ?? {}, fieldPathArray, value()) : value()
                return ref.set(newValue)
              })
            
          } else {
            if (value) {
              if (fieldPath) {
                ref.get(path).then(async prev => {
                  const rawData = prev.data()
                  const newValue = setIn(rawData, path, value)
                  rawData ? await ref.set(fieldPath, newValue) : await ref.set(fieldPath, value)
                  return
                })
                  .catch(async _ => {
                    const newValue = setIn({}, path, value)
                    await ref.set(fieldPath, newValue)
                    return
                  })
                
              }
              ref.get(path).then((prev) => {
                const rawData = prev.data()
                value.id = ref.id
                const newValue = fieldPath ? setIn(rawData, path, value) : value
                fieldPath ? ref.update(fieldPath, newValue) : ref.set(newValue)
                return
              })
                .catch((_) => {
                  ref.set(value)
                  return
                })
              
            } else {
              if (fieldPath) {
                await ref.update({
                  [fieldPath]: clientSide.firestore.FieldValue.delete()
                })
                return
              }
              await ref.delete()
              return
            }
          }
        } else {
          const [file, ...properties] = pathNodes[1].path.split('/')
          const fileProperties =
            properties && properties.length ? `/${properties.join('')}` : ''
          const fileCompletePath = path + '/' + file + fileProperties
          if (value) {
            return await app
              .storage()
              .bucket(storageKey)
              .file(fileCompletePath)
              .save(JSON.stringify(value), {
                public: true,
                contentType: 'application/json',
              })
          } else {
            return await app
              .storage()
              .bucket(storageKey)
              .file(fileCompletePath)
              .delete()
          }
        }
      } catch (e) {
        console.warn(e)
      }
    },
  }
}

async function getRaw (db, bucketName, fileName, clientSide) {
  try {
    if (typeof db.bucket !== 'function') {
      if (clientSide) {
        const url = await db.ref(fileName).getDownloadURL()
        const result = await fetch(url).then(r => {
          return r.json()
        })
        return result
      }
      const downloadUrl = db.ref(fileName).getDownloadURL()
      if (!downloadUrl) return null
      
      return fetch(downloadUrl).then((r) => r.json())
    }
    const snap = db.bucket(bucketName).file(fileName)
    const [exists] = await snap.exists()
    if (!exists) return null
    
    const [dd] = await snap.download()
    if (!dd) return null
    
    const raw = dd.toString()
    return JSON.parse(raw)
  } catch (e) {
    console.warn(e)
    return null
  }
}

const calculate = (pathNodes, db, join) => {
  return pathNodes.reduce(
    ({ ref }, { type, path }) => {
      switch (type) {
        case collectionType:
          return {
            ref: ref.collection(path),
            fieldPath: null,
          }
        case pathType:
          const keys = path.split('/')
          return {
            ref: ref.doc(keys[0]),
            fieldPath: join ? keys.slice(1).join('.') : keys[1],
          }
        case collectionGroupType:
          return {
            ref: ref.collectionGroup(path),
            fieldPath: null,
          }
        default:
          return { ref: ref, fieldPath: null }
      }
    },
    { ref: db, fieldPath: '' }
  )
}
