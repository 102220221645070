import { campaignSet } from '../../../../saga-tools/Data.effects'

export function * phase2ModuleEnded (event) {
  const buildingType = event?.buildingType
  const moduleStep = event?.moduleStep
  yield campaignSet(`sessions/${event.target}`, v => {
    return ({
      ...v,
      buildingProgression: event?.unlockNextBuilding ? v?.buildingProgression ? parseInt(v?.buildingProgression + 1)
          : 1
        : v?.buildingProgression,
      buildings: {
        ...v?.buildings,
        [buildingType]: {
          ...v?.buildings?.[buildingType],
          stepsPlayed: {
            ...v?.buildings?.[buildingType]?.stepsPlayed,
            [moduleStep]: {
              ...v?.buildings?.[buildingType]?.stepsPlayed?.[moduleStep],
              win: true
            }
          }
        }
      }
    })
  })
}

export function * phase2BuildingSeenUpdated (event) {
  const buildingType = event?.buildingType === 'merveille' ? 'wonder' : event?.buildingType
  const step = event?.step
  
  yield campaignSet(`sessions/${event.target}/progressionSeen/${buildingType}/step`,
    () => step)
}

export function * phase2WonderNotificationSeen (event) {
  const userId = event?.target
  
  yield campaignSet(`sessions/${userId}/progressionSeen/wonder/notifyEvolution`,
    () => false)
}

export function * phase2CampaignEndedModalSeen (event) {
  const userId = event?.target
  
  yield campaignSet(`states/${userId}/campaignEndedModalSeen`,
    () => true)
}

export function * phase2BuildingLocked (event) {
}

export function * phase2BuildingUnlocked (event) {

}

