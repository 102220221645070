import {campaignGet, sendEvent} from "../../../../saga-tools/Data.effects"
import {createFunctionalError} from "../../../../saga-tools/Errors"
import {profileErrors} from "../../../Profile/Profile.errors"

export function* phase2UpdateSeeTutoStep(command) {
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)
  yield sendEvent('Phase2UpdateTutoStepSeen', {
    step: command?.step,
  })
}
