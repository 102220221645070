import { campaignGet, campaignSet, publicProfileGet, sendEvent, stateGet } from '../../../../saga-tools/Data.effects'
import { call }                                                            from 'redux-saga/effects'

export function pointsUpdated(event){
  return call(wrappedPointsUpdated, event)
}

export function * wrappedPointsUpdated (event) {
  const userId = event.target
  const userProfile = yield publicProfileGet(userId)
  const phase = event?.phase
  yield sendEvent('UserPointsUpdated',
    {
      points: event.points,
      target: userId,
      origin: event?.origin,
      challengeId: event?.challengeId,
      moduleType: event?.moduleType ,
      userName: userProfile?.name
    }
  )
  const dateNow = new Date(Date.now()).toISOString()
  const campaignSettings = yield campaignGet('settings')
  const campaignEnded = campaignSettings?.['endAt'] < dateNow
  if (!campaignEnded && (!phase || phase === 1)) {
    const userState = yield stateGet(userId)
    const noTeamPointsRatio = campaignSettings?.noTeamPointsRatio
    if (userState?.teamId) {
      const teamName = yield campaignGet(`teams/${userState.teamId}/name`)
      const teamMembers = yield campaignGet(`teams/${userState.teamId}/members`)
      const teamRatio = noTeamPointsRatio ? 1 :
        teamMembers.length >= 19 ? 1 + ((10 - 19) * .1)
          : 1 + ((10 - teamMembers.length) * .1)
      yield sendEvent('TeamPointsUpdated',
        {
          points: Math.ceil(event.points * teamRatio),
          target: userId,
          teamId: userState.teamId,
          origin: event?.origin,
          teamName: teamName
        }
      )
    }
  }
}

export function * userPointsUpdated (event) {
  yield campaignSet(`states/${event.target}/points`, (p) => increase(p, event.points))
  yield campaignSet(`leaderboards/leaderboard/${event.target}`, (p) => increase(p, event.points))
}

export function * teamPointsUpdated (event) {
  yield campaignSet(`teams/${event?.teamId}/points`, (p) => increase(p, event.points))
  yield campaignSet(
    `teams/${event?.teamId}/contributions/${event.target}`,
    (p) => increase(p, event.points)
  )
  yield campaignSet(`leaderboards/teamLeaderboard/${event?.teamId}`, (p) => increase(p, event.points))
}

const increase = (base, increase) => {
  return (base || 0) + (increase || 0)
}
