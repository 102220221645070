import { campaignGet, sendEvent, stateGet } from '../../../../saga-tools/Data.effects'
import { createFunctionalError }                              from '../../../../saga-tools/Errors'
import { profileErrors }                                      from '../../../Profile/Profile.errors'

const defaultLock = 2

export function * phase2EndModule (command) {
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)
  
  const userState = yield stateGet(command.emitter)
  const buildingType = command?.buildingType
  const moduleStep = command?.moduleStep
  const reward = command?.reward
  const moduleResult = command?.result
  const currentModule = yield campaignGet(`sessions/${command.emitter}/buildings/${buildingType}/stepsPlayed/${moduleStep}`)
  const moduleAlreadyPlayed = currentModule?.win
  const teamId = yield campaignGet(`states/${command.emitter}/teamId`)
  
  const dateNow = new Date(Date.now()).toISOString()
  const campaignSettings = yield campaignGet('settings')
  const campaignEnded = campaignSettings?.['endAt'] < dateNow
  
  const playerFullOnBoarding = campaignEnded && !teamId
  
  if (!moduleResult || !userState) return
  
  const lockBuildingsFromOrder = campaign?.settings?.lockBuildingsFromOrder
  const teamBuildingStep = (playerFullOnBoarding || lockBuildingsFromOrder) ?
    yield campaignGet(`states/${command.emitter}/onBoardingGameState/buildingsState/${buildingType}/step`)
    : yield campaignGet(`teams/${teamId}/buildingsState/${buildingType}/step`)
  
  const buildingsSettings = yield campaignGet(`settings/buildings`)
  const buildingMaxStep = Object.values(buildingsSettings || {}).filter(v => v.type === buildingType)?.[0]?.steps
  
  if (!command.PWA && (parseInt(teamBuildingStep) === parseInt(moduleStep) && (parseInt(teamBuildingStep) !== parseInt(buildingMaxStep))) || !teamBuildingStep) {
    yield sendEvent('Phase2BuildingEvolved', {
      buildingType: buildingType,
      teamId: teamId,
      playerFullOnBoarding: playerFullOnBoarding || lockBuildingsFromOrder
    })
  }
  
  if (!moduleAlreadyPlayed) {
    const lastLevelModule = moduleStep >= buildingMaxStep
    const buildingSettings = Object.values(campaignSettings?.buildings || {}).filter(v => v.type === buildingType)[0]
    const buildingOrder = buildingSettings?.order
    const currentBuildingProgression = yield campaignGet(`sessions/${command.emitter}/buildingProgression`)
    const lastBuildingUnlocked = currentBuildingProgression === buildingOrder
    yield sendEvent('Phase2ModuleEnded', {
      buildingType: buildingType,
      moduleStep: moduleStep,
      unlockNextBuilding: lastBuildingUnlocked && lastLevelModule && !moduleAlreadyPlayed && lockBuildingsFromOrder
    })
  } else {
    const lock = campaign?.settings?.lockModules ?? defaultLock
    yield sendEvent('Phase2BuildingLocked', {
      buildingType: buildingType,
      lock: lock
    })
  }
  
  if (reward?.flouz) {
    yield sendEvent('Phase2FlouzWin', {
      flouz: Math.round(reward.flouz),
    })
  }
  
}
