import { campaignGet, sendEvent, sessionGet, stateGet } from '../../../../saga-tools/Data.effects'
import { createFunctionalError }                        from '../../../../saga-tools/Errors'
import { profileErrors }                                from '../../../Profile/Profile.errors'
import { calculateWin, getModulePoints }                from './Session.helpers'
import { canEndChallenge }                              from '../Challenges/Challenges.helpers'
import { sessionErrors }                                from './Session.errors'
import { pointsUpdated }                                from '../Points/Points.reducers'
import { call }                                         from 'redux-saga/effects'

export const DEFAULT_WON_CHALLENGE_BONUS = 100
export const DEFAULT_EQUALITY_CHALLENGE_BONUS = 50
export const DEFAULT_BOT_RESULT = 2

export function * updateModuleStateUserSession (command) {
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)
  const levelNumber = parseInt(command?.level?.split('-')[1]) || 1

  const userState = yield stateGet(command.emitter)
  const session = yield sessionGet(command.emitter)
  const currentLevel = session?.[command?.module]?.[command?.level]
  const phase = command?.phase
  const campaignSettings = yield campaignGet('settings')
  if (currentLevel) return
  const settingsWonChallengeBonus = campaignSettings?.wonChallengeBonus
  const wonChallengeBonus = settingsWonChallengeBonus || DEFAULT_WON_CHALLENGE_BONUS
  if (command.quizBoss) {
    const bossResult = 3
    const isWin = command.result > bossResult
    if (!isWin) {
      return yield sendEvent('BossModuleFailed', {
        module: command.module,
        level: command.level,
        theme: command.theme,
        result: command?.result
      })
    }
    yield sendEvent('BossModuleSucceed', {
      module: command.module,
      level: command.level,
      theme: command.theme,
      result: command?.result
    })
    yield sendModuleEnded(command)
    yield pointsUpdated({
      points: yield getModulePoints(command.module, command.result, levelNumber),
      origin: 'ModulesPoints',
      moduleType: command.module,
      challengeId: command.challengeId,
      opponentResult: yield getModulePoints('quizzes', bossResult),
      result: command.result,
      target: command.emitter,
      campaignId: command.campaignId,
      clientId: command.clientId
    })
    yield pointsUpdated({
      points: wonChallengeBonus,
      moduleType: command.module,
      origin: 'ChallengePoints',
      challengeId: command.challengeId,
      target: command.emitter,
      campaignId: command.campaignId,
      clientId: command.clientId
    })
  }

  if (!command.challengeId) {
    if (userState && !!command.win) {
      yield sendModuleEnded(command)
      yield pointsUpdated({
        points: yield getModulePoints(command.module, command.result, levelNumber),
        origin: 'ModulesPoints',
        moduleType: command.module,
        challengeId: command.challengeId,
        opponentResult: yield getModulePoints(command.module, 2),
        result: command.result,
        target: command.emitter,
        campaignId: command.campaignId,
        clientId: command.clientId,
        phase: phase
      })
    } else {
      if (campaignSettings?.useThemes) {
        return yield sendEvent('ModuleFailedV2', {
          module: command.module,
          level: command.level,
          theme: command.theme
        })
      }
      yield sendEvent('ModuleFailed', {
        module: command.module,
        level: command.level,
        theme: command.theme
      })
      return
    }
  }
  if (!command.challengeId) return
  const challenge = yield campaignGet(`sessions/${command.emitter}/challenges/${command.challengeId}`)

  if (command?.challengeId && challenge?.opponentUid.includes('bot-')) {
    yield sendModuleEnded(command)
    const botResultConfig = campaignSettings?.botResult
    yield pointsUpdated({
      points: yield getModulePoints(command.module, command.result, levelNumber),
      origin: 'ModulesPoints',
      moduleType: command.module,
      challengeId: command.challengeId,
      opponentResult: yield getModulePoints('quizzes', (botResultConfig || DEFAULT_BOT_RESULT)),
      result: command.result,
      target: command.emitter,
      campaignId: command.campaignId,
      clientId: command.clientId,
      phase: phase
    })
  } else {
    const points = yield getModulePoints(command.module, command.result, levelNumber)
    yield pointsUpdated({
      points: points,
      result: command.result,
      moduleType: command.module,
      origin: 'ModulesPoints',
      challengeId: command.challengeId,
      opponentResult: command?.opponentResult,
      target: command.emitter,
      campaignId: command.campaignId,
      clientId: command.clientId,
      phase: phase
    })
    if (!command?.challenged) {
      yield sendModuleEnded(command)
    }
  }

  let opponentChallengeStatus
  let win
  if (challenge) {

    if (challenge?.opponentUid.startsWith('bot--')) {
      win = calculateWin(command.result, 2)
      opponentChallengeStatus = 'ended'
    } else {
      const opponentId = challenge?.opponentUid
      const opponentChallenge = yield campaignGet(`sessions/${opponentId}/challenges/${command.challengeId}`)
      opponentChallengeStatus = opponentChallenge?.status
      win = calculateWin(command.result, opponentChallenge?.result)
    }
    if ((challenge.status === 'started' || challenge.status === 'ended') &&
      (opponentChallengeStatus === 'ended' || opponentChallengeStatus === 'seen')) {
      const settingsEqualityChallengeBonus = campaignSettings?.equalityChallengeBonus
      const equalityChallengeBonus = settingsEqualityChallengeBonus || DEFAULT_EQUALITY_CHALLENGE_BONUS

      if (win === 'win') {
        yield pointsUpdated({
          points: wonChallengeBonus,
          moduleType: command.module,
          origin: 'ChallengePoints',
          challengeId: command.challengeId,
          target: command.emitter,
          campaignId: command.campaignId,
          clientId: command.clientId,
          phase: phase
        })
      }
      if (win === 'equality') {
        yield pointsUpdated({
          points: equalityChallengeBonus,
          moduleType: command.module,
          origin: 'ChallengePoints',
          challengeId: command.challengeId,
          target: command.emitter,
          campaignId: command.campaignId,
          clientId: command.clientId,
          phase: phase
        })
      }

      yield sendEvent('ChallengeModuleSeen', {
        win: command.win,
        challengeId: command.challengeId,
        result: command.result
      })
    } else {
      const opponentId = challenge?.opponentUid
      yield sendEvent('ChallengeModuleEnded', {
        win: command.win,
        challengeId: command.challengeId,
        result: command.result,
        timeStampStart: command.timeStampStart,
        timeStampEnd: command.timeStampEnd,
        opponentId: opponentId
      })
    }
  }
}

export function * seeChallengeResult (command) {
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)

  const challenge = yield campaignGet(`sessions/${command.emitter}/challenges/${command.challengeId}`)
  if (!challenge) return
  const opponentId = challenge?.opponentUid
  const opponentChallenge = yield campaignGet(`sessions/${opponentId}/challenges/${command.challengeId}`)
  const campaignSettings = yield campaignGet('settings')
  const campaignEndChallengesHours = campaignSettings?.challengeEndDate
  const campaignEndChallenge = campaignEndChallengesHours ? canEndChallenge(challenge.timeStamp, campaignEndChallengesHours)
    : canEndChallenge(challenge.timeStamp)
  if (opponentChallenge?.status === 'started' && !campaignEndChallenge) {
    throw createFunctionalError(sessionErrors.cannotSeeChallenge)
  }

  const forfaitChallenge = (!opponentChallenge || opponentChallenge?.status === 'started') && campaignEndChallenge
  const win = forfaitChallenge ? 'win' : calculateWin(challenge?.result, opponentChallenge?.result)

  if (forfaitChallenge && opponentChallenge) {
    sendEvent('OpponentChallengeModuleSeen', {
      win: command.win,
      challengeId: command.challengeId,
      result: command.result,
      opponentId: opponentId
    })
  }

  const settingsWonChallengeBonus = campaignSettings?.wonChallengeBonus
  const settingsEqualityChallengeBonus = campaignSettings?.equalityChallengeBonus
  const wonChallengeBonus = settingsWonChallengeBonus || DEFAULT_WON_CHALLENGE_BONUS
  const equalityChallengeBonus = settingsEqualityChallengeBonus || DEFAULT_EQUALITY_CHALLENGE_BONUS

  yield sendEvent('ChallengeModuleSeen', {
    win: command.win,
    challengeId: command.challengeId,
    result: command.result
  })

  if (win === 'win') {
    yield pointsUpdated({
      points: wonChallengeBonus,
      moduleType: command.module,
      origin: 'ChallengePoints',
      challengeId: command.challengeId,
      target: command.emitter,
      campaignId: command.campaignId,
      clientId: command.clientId,
      win: 'win'
    })
  } else if (win === 'equality') {
    yield pointsUpdated({
      points: equalityChallengeBonus,
      moduleType: command.module,
      origin: 'ChallengePoints',
      challengeId: command.challengeId,
      target: command.emitter,
      campaignId: command.campaignId,
      clientId: command.clientId,
      win: 'equality'
    })
  } else if (win === 'loose') {
    yield pointsUpdated({
      points: 0,
      moduleType: command.module,
      origin: 'ChallengePoints',
      challengeId: command.challengeId,
      target: command.emitter,
      campaignId: command.campaignId,
      clientId: command.clientId,
      win: 'loose'
    })
  }

}

function sendModuleEnded (command) {
  return call(wrappedSendModuleEnded, command)
}

function * wrappedSendModuleEnded (command) {
  const campaignSettings = yield campaignGet('settings')
  const currentWorldSettings = command?.currentWorldSettings
  const content = {
    'lv-1': 'lv-1',
    'lv-2': 'lv-2',
    'lv-3': 'lv-3',
    'lv-4': 'lv-4',
    'lv-5': 'lv-5',
    'lv-6': 'lv-6',
    'lv-7': 'lv-7',
  }
  const levelNumber = parseInt(command?.level?.split('-')[1]) || 1
  const nextLevel = `lv-${levelNumber + 1}`
  if (campaignSettings?.useThemes) {
    const themeSettings = currentWorldSettings ? currentWorldSettings?.themes : campaignSettings?.themes
    const themeLevelsCount = currentWorldSettings ? Object.keys(themeSettings?.levels) : Object.keys(themeSettings?.[command.moduleName]?.levels)
    yield sendEvent('ModuleEndedV2', {
      module: command.module,
      level: command.level,
      tilePosition: command.tilePosition,
      destination: command.destination,
      nextLevel: themeLevelsCount.includes(nextLevel) ? nextLevel : 'ended',
      win: command.win,
      attemptsLeft: command.attemptsLeft,
      timeStampStart: command.timeStampStart,
      timeStampEnd: command.timeStampEnd,
      answers: command.answers,
      picturesTimeStamps: command.picturesTimeStamps,
      infosModalsTimeStamps: command.infosModalsTimeStamps,
      theme: command.theme,
      phase: command?.phase
    })
    return
  } else {
    yield sendEvent('ModuleEnded', {
      module: command.module,
      level: command.level,
      tilePosition: command.tilePosition,
      destination: command.destination,
      nextLevel: content[nextLevel] ? nextLevel : 'ended',
      win: command.win,
      attemptsLeft: command.attemptsLeft,
      timeStampStart: command.timeStampStart,
      timeStampEnd: command.timeStampEnd,
      answers: command.answers,
      picturesTimeStamps: command.picturesTimeStamps,
      infosModalsTimeStamps: command.infosModalsTimeStamps,
      phase: command?.phase
    })
    return
  }
}
