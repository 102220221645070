import { useState } from "react";

export const useIsActive = () => {
  const [active, setActive] = useState(false);

  return [
    active,
    {
      onPressIn: () => setActive(true),
      onPressOut: () => setActive(false),
    },
  ];
};
