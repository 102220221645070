import React, {useEffect, useState} from 'react'
import styled from "@emotion/native"
import {Animated} from "react-native"

const assets = {
  background: require('../Assets/backgroud-start-screen.png'),
  thunder: require('../Assets/thunder-yellow.png'),
  logo: require('../Assets/logo.png'),
  logoMobile: require('../Assets/logo-mobile-start-screen.png'),
  quizBoss: require('../Assets/logo-duel-boss.png'),
  quizBossMobile: require('../Assets/logo-duel-boss-mobile.png')
}

export const StartScreen = ({screenDimensions, initialized, mobile, quizBoss}) => {
  const [thunderAnimationScale] = useState(new Animated.Value(0))
  const [logoAnimationScale] = useState(new Animated.Value(5))
  const [logoAnimationOpacity] = useState(new Animated.Value(0))
  useEffect(() => {
    Animated.parallel([
      Animated.timing(thunderAnimationScale, {toValue: 1, duration: 1500, useNativeDriver: true}),
      Animated.timing(logoAnimationScale, {toValue: 1, duration: 1500, useNativeDriver: true}),
      Animated.timing(logoAnimationOpacity, {toValue: 1, duration: 1500, useNativeDriver: true}),
    ]).start(() => setTimeout(() => initialized(), 500))
  }, [])

  return <WrapperStartScreen screenDimensions={screenDimensions}>
    <BackgroundStartScreen source={assets.background}
                           alt='background start screen'
                           screenDimensions={screenDimensions}/>
    <ThunderStartScreen source={assets.thunder}
                        alt='background thunder'
                        screenDimensions={screenDimensions}
                        style={{transform: [{scale: thunderAnimationScale}]}}/>
    <LogoStartScreen
      source={quizBoss ? mobile ? assets.quizBossMobile : assets.quizBoss : mobile ? assets.logoMobile : assets.logo}
      alt='logo start screen'
      quizBoss={quizBoss}
      mobile={mobile}
      screenDimensions={screenDimensions}
      style={{transform: [{scale: logoAnimationScale}], opacity: logoAnimationOpacity}}/>
  </WrapperStartScreen>
}

const WrapperStartScreen = styled.View(({screenDimensions}) => ({
  width: screenDimensions.width,
  height: screenDimensions.height,
  alignItems: 'center',
  justifyContent: 'center'
}))

const BackgroundStartScreen = styled.Image(({screenDimensions}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: screenDimensions.width,
  height: screenDimensions.height
}))

const ThunderStartScreen = styled(Animated.Image)(({screenDimensions}) => ({
  width: (screenDimensions.height * 0.8) * 0.45,
  height: screenDimensions.height * 0.8
}))

const LogoStartScreen = styled(Animated.Image)(({screenDimensions, mobile, quizBoss}) => ({
  position: 'absolute',
  width: mobile ? screenDimensions.width * 0.7 : screenDimensions.width * 0.33,
  height: mobile ? quizBoss ? (screenDimensions.width * 0.75) * 0.7
    : (screenDimensions.width * 0.7) * 0.65
    : quizBoss ? (screenDimensions.width * 0.36) * 0.18
      : (screenDimensions.width * 0.33) * 0.18,
  top: (screenDimensions.height - ((mobile ? (screenDimensions.width * 0.7) * 0.65 : (screenDimensions.width * 0.33) * 0.18) / 2)) / 2,
  left: (screenDimensions.width - (mobile ? screenDimensions.width * 0.7 : screenDimensions.width * 0.33)) / 2
}))
