import React from "react"
import styled from "@emotion/native"
import {colors} from "../../configs/colors"
import {StarRoundIcon} from "../Icons"
import {Title2} from "../Texts"
import WonderPoints from '../../../assets/wonder-points.svg'
import YellowPoints from '../../../assets/yellow-point.svg'

export const Points = ({count, fontSize, phase, type, ...props}) => (
  <PointsWrapper {...props}>
    <PointsLabel {...{fontSize}}>{count || 0}</PointsLabel>
    {
      phase === 1 ?
        <StarRoundIcon
          color={colors.extraLightBackground}
          width={25}
          height={25}
          wrapperColor={colors.text}
        /> : phase === 2 ? (type === 'team' ?
        <WonderPoints
          width={25}
          height={25}
        /> :
        <YellowPoints width={25}
                      height={25}/>)
        : <StarRoundIcon
          color={colors.extraLightBackground}
          width={25}
          height={25}
          wrapperColor={colors.text}
        />}
  </PointsWrapper>
)

const PointsWrapper = styled.View({
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: 30
})

const PointsLabel = styled(Title2)(
  {
    marginRight: 5,
  },
  ({fontSize}) =>
    fontSize && {
      fontSize,
    }
)
