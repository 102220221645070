import {campaignSet, set} from "../../../../saga-tools/Data.effects"

export function* playerPositionUpdated(event){
  if(event?.phase === 1.3){
    yield campaignSet(`states/${event.target}/worlds/${event?.currentWorld}/map/playerPosition`, {...event.playerPosition})
  }else {
    yield campaignSet(`states/${event.target}/map/playerPosition`, {...event.playerPosition})
  }
}

export function* fogMapUpdated(event){
  const newMap = JSON.stringify(event.fogMap)
  if(event?.phase === 1.3){
    yield set(`clients/${event.clientId}/campaigns/${event.campaignId}/states/${event.target}/worlds/${event?.currentWorld}/map`, (mapState) => ({
      ...mapState,
      fogMap: newMap
    }))
  }else {
    yield set(`clients/${event.clientId}/campaigns/${event.campaignId}/states/${event.target}/map`, (mapState) => ({
      ...mapState,
      fogMap: newMap
    }))
  }
}
