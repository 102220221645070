import { campaignGet } from '../../../../saga-tools/Data.effects'

const DEFAULT_QUIZ_GOOD_ANSWERS_BONUS = 10
const DEFAULT_MASTERMIND_ATTEMPTS_LEFT_BONUS = 10
const DEFAULT_MEMORY_ATTEMPTS_LEFT_BONUS = 10


export function calculateWin (result, opponentResult) {
  if ((result || 0) > (opponentResult || 0)) {
    return 'win'
  }
  if ((result || 0) === (opponentResult || 0)) {
    return 'equality'
  }
  if ((result || 0) < (opponentResult || 0)) {
    return 'loose'
  }
}

export function * getModulePoints (module, result, levelNumber) {
  const customGoodAnswersBonus = yield campaignGet('settings.quizzesGoodAnswerBonus')
  const goodAnswersBonus = customGoodAnswersBonus || DEFAULT_QUIZ_GOOD_ANSWERS_BONUS
  switch (module) {
    case 'quizHarbor':
      return (goodAnswersBonus * result * levelNumber)
    case 'quizTunnel':
      return (goodAnswersBonus * result * levelNumber)
    case 'quiz':
      return (goodAnswersBonus * result * levelNumber)
    case 'mastermind':
      const customMastermindAttemptsLeftBonus = yield campaignGet('settings.mastermindAttemptsLeftBonus')
      const mastermindAttemptsLeftBonus = customMastermindAttemptsLeftBonus || DEFAULT_MASTERMIND_ATTEMPTS_LEFT_BONUS
      return getMastermindPoints(result, mastermindAttemptsLeftBonus, levelNumber)
    case 'memory':
      const customMemoryAttemptsLeftBonus = yield campaignGet('settings.memoryAttemptsLeftBonus')
      const memoryAttemptsLeftBonus = customMemoryAttemptsLeftBonus || DEFAULT_MEMORY_ATTEMPTS_LEFT_BONUS
      return getMemoryPoints(result, memoryAttemptsLeftBonus, levelNumber)
  }
}

export const getMastermindPoints = (result, mastermindAttemptsLeftBonus, levelNumber) => {
  return result * mastermindAttemptsLeftBonus * levelNumber
}

export const  getMemoryPoints = (result, memoryAttemptsLeftBonus, levelNumber) => {
  return result * memoryAttemptsLeftBonus * levelNumber
}
