import React                from 'react'
import styled               from '@emotion/native'
import { colors }           from '@civitime/library/storybook/configs/colors'
import { TextArrowButton }  from '@civitime/library/storybook/stories/Button'
import { UseLinkInText }    from '../useLinkInText'
import { useTranslator, T } from '../translations/translate'

const handsResult = {
  win: require('../Assets/hand-result-good.png'),
  loose: require('../Assets/hand-result-wrong.png'),
}

export const QuestionEnded = ({
  question,
  result,
  launchNextQuestion,
  quizEnded,
  showResults,
  mobile,
  contentTranslations,
  phase
}) => {
  const { t } = useTranslator()
  const goodAnswer = question.answers.filter(q => q?.goodAnswer)?.[0]?.text
  const questionNumber = goodAnswer.split('.')[1]
  
  return (
    <WrapperQuestionResult>
      <ResultQuestionHeader resultStatus={result?.[question.id]}>
        <WrapperHandResult>
          <HandResult
            resizeMode="contain"
            source={result?.[question.id] ? handsResult.win : handsResult.loose}
            alt="hand result"
          />
        </WrapperHandResult>
        <ResultStatusText>
          {result?.[question.id] ? t('app.questionEnded.goodAnswer') : t('app.questionEnded.badAnswer')}
        </ResultStatusText>
      </ResultQuestionHeader>
      <ResultQuestionExplanation>
        {!result?.[question.id] && (
          <ResultQuestionTextExplanation mobile={mobile} bold>
            {t('app.questionEnded.goodAnswerWas')}
            {phase === 1 ? <UseLinkInText
                text={contentTranslations ? contentTranslations?.[`answers.${questionNumber}`]?.[goodAnswer] : goodAnswer}/>
              : <T path={goodAnswer}/>}
          </ResultQuestionTextExplanation>
        )}
        <ResultQuestionTextExplanation mobile={mobile}>
          <T path={question?.explanationEnded}/>
        </ResultQuestionTextExplanation>
        <ResultQuestionTextExplanationSoft mobile={mobile}>
          {phase === 1.3 ? <T
              path={question.endedExplanation}/>
            : <UseLinkInText
              text={contentTranslations ? contentTranslations[question.endedExplanation] : question.endedExplanation}/>}
        </ResultQuestionTextExplanationSoft>
      </ResultQuestionExplanation>
      <ButtonNextQuestion
        backgroundColor={colors.dropShadow}
        backgroundColorHover={colors.dropShadow}
        textColor={colors.dropShadow}
        textHoverColor={colors.lightBackground}
        onPress={quizEnded ? showResults : launchNextQuestion}
      >
        <NextQuestionTextButton>
          {quizEnded ? t('app.questionEnded.quizEnded') : t('app.questionEnded.nextQuestion')}
        </NextQuestionTextButton>
      </ButtonNextQuestion>
    </WrapperQuestionResult>
  )
}

const WrapperQuestionResult = styled.View({})

const ResultQuestionHeader = styled.View(({ resultStatus }) => ({
  height: 50,
  backgroundColor: resultStatus ? colors.secondary : colors.highlightDanger,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  borderRightColor: colors.dropShadow,
  borderLeftColor: colors.dropShadow,
  borderTopColor: 'transparent',
  borderBottomColor: 'transparent',
  borderWidth: 1,
}))

const ResultQuestionExplanation = styled.View({
  backgroundColor: 'white',
  borderBottomColor: colors.dropShadow,
  borderRightColor: colors.dropShadow,
  borderLeftColor: colors.dropShadow,
  borderTopColor: 'transparent',
  borderWidth: 1,
  borderBottomRightRadius: 9,
  borderBottomLeftRadius: 9,
})

const ResultStatusText = styled.Text({
  fontSize: 15,
  color: colors.lightBackground,
  fontWeight: 'bold',
})

const WrapperHandResult = styled.View({
  width: 50,
  height: '100%',
  marginRight: 15,
})

const HandResult = styled.Image({
  position: 'initial',
  justifySelf: 'flex-end',
  textAlign: 'right',
})

const ResultQuestionTextExplanation = styled.Text(({ mobile, bold }) => ({
  padding: 15,
  fontSize: mobile ? 14 : 18,
  color: colors.dropShadow,
  fontWeight: bold && 'bold',
}))

const ResultQuestionTextExplanationSoft = styled(ResultQuestionTextExplanation)(
  ({ mobile }) => ({
    fontSize: mobile ? 14 : 18,
    fontWeight: 'normal',
  })
)

const ButtonNextQuestion = styled(TextArrowButton)({
  position: 'absolute',
  right: 0,
  bottom: -55,
  maxWidth: 205,
  height: 35,
})

const NextQuestionTextButton = styled.Text({
  fontSize: 13,
})
