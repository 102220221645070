export const bots = [
  {
    id: "bot--0",
    avatar: {type: "man", color: "#E0A39A", tShirt: '#F2F2F2'},
    name: "Alphonse",
  },
  {
    id: "bot--1",
    avatar: {type: "man", color: "#FCD7B8", tShirt: '#3F475F'},
    name: "Fred",
  },
  {
    id: "bot--2",
    avatar: {type: "woman", color: "#533724", tShirt: '#F2F2F2'},
    name: "Nina",
  },
  {
    id: "bot--3",
    avatar: {type: "woman", color: "#fcd7b8", tShirt: '#88c10f'},
    name: "Steph'",
  },
  {
    id: "bot--4",
    avatar: {type: "man", color: "#fcd7b8", tShirt: '#F2F2F2'},
    name: "Nico",
  },
  {
    id: "bot--5",
    avatar: {type: "woman", color: "#533724", tShirt: '#F2F2F2'},
    name: "Marie",
  },
  {
    id: "bot--6",
    avatar: {type: "woman", color: "#533724", tShirt: '#F2F2F2'},
    name: "Jasmine",
  },
]