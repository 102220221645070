import React from "react";
import styled from "@emotion/native";
import { colors } from "../../configs/colors";

export const ErrorAlert = styled.View(({success=false}) => ({
  margin: 15,
  paddingVertical: 8,
  paddingHorizontal: 15,
  backgroundColor: success? colors.vividBlue : colors.watermelon,
}));

export const ErrorText = styled.Text({
  color: colors.lightText,
});
