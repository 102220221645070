import addDays               from "date-fns/addDays"
import addHours               from "date-fns/addHours"
import isAfter               from "date-fns/isAfter"
import { clearedOffsetDate } from '../../../ActionPoints/ActionPoints.helpers'

const DEFAULT_END_CHALLENGES_HOURS = 24

export function canEndChallenge(challengesStart, campaignSettingsEndChallenges, now= new Date()) {
  const awaitingHours = campaignSettingsEndChallenges ?? DEFAULT_END_CHALLENGES_HOURS
  const endDate = addHours(clearedOffsetDate(challengesStart), awaitingHours)
  return isAfter(
    clearedOffsetDate(now),
    endDate,
  )
}
