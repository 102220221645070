import { take, call, setContext, put, fork } from 'redux-saga/effects'
import { isFunctionalError } from './Errors'
import seedRandom from 'seedrandom'

export function createSafeSagaLoop (sagas, endType) {
  return function * safeSagaLoop () {
    while (true) {
      const message = yield take('*')
      yield fork(run, message, sagas, endType)
    }
  }
}
function * run (message, sagas, endType) {
  const associatesSagas = sagas[message.type] || []
  for (let saga of associatesSagas) {
    yield call(safeSagaRun, saga, message)
  }
  yield put({
    type: endType,
    payload: {}
  })
}

function * safeSagaRun (saga, message) {
  const currentUserId = message.payload.target || message.payload.emitter
  const date = message.payload.timeStamp
  try {
    yield setContext({
      randomSeed: message.id,
      random: seedRandom(message.id),
      message: message,
      messageDate: date
        ? new Date(date).toISOString()
        : new Date().toISOString(),
      clientId: message.payload.clientId,
      campaignId: message.payload.campaignId,
      currentUserId: currentUserId
    })
    yield call(saga, message.payload)
  } catch (e) {
    if (isFunctionalError(e)) {
      console.warn(e)
      yield put({
        ...e,
        isError: true,
        payload: {
          clientId: message.payload.clientId,
          campaignId: message.payload.campaignId,
          target: currentUserId,
          timeStamp: date,
          ...e.payload
        }
      })
    } else {
      console.error(e)
      yield put({
        type: 'server_technical_error',
        isError: true,
        payload: {
          clientId: message.payload.clientId,
          campaignId: message.payload.campaignId,
          target: currentUserId,
          timeStamp: date,
          message: e?.message
        }
      })
    }
  }
}
