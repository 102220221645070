import {campaignGet, sendEvent, stateGet} from "../../../../saga-tools/Data.effects"
import {createFunctionalError} from "../../../../saga-tools/Errors"
import {profileErrors} from "../../../Profile/Profile.errors"

export function* phase2InvestInBuilding(command) {
  const campaign = yield campaignGet()

  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)
  const userState = yield stateGet(command.emitter)
  const buildingType = command?.buildingType
  const userFlouz = userState?.flouz
  const teamId = command?.teamId
  const investedFlouz = command?.flouz
  const dateNow = new Date(Date.now()).toISOString()
  const campaignSettings = yield campaignGet('settings')
  const campaignEnded = campaignSettings?.['endAt'] < dateNow
  const playerFullOnBoarding = true

  const teamData = playerFullOnBoarding ?
    yield campaignGet(`states/${command.emitter}/onBoardingGameState`)
    : yield campaignGet(`teams/${teamId}`)

  if (!userFlouz || userFlouz < investedFlouz || (!teamId && !campaignEnded)) return

  const teamPoints = teamData?.wonderState?.points

  const teamGoalAdded = playerFullOnBoarding ?
    campaign?.settings?.wonderRules?.[teamData?.wonderState?.step + 1 || 2]?.goalAddedOnBoarding
    : campaign?.settings?.wonderRules?.[teamData?.wonderState?.step + 1 || 2]?.goalAdded

  const buildingGoal = playerFullOnBoarding ?
    campaign?.settings?.wonderRules?.[(teamData?.wonderState?.step || 1) + 1]?.goalOnBoarding
    
    :campaign?.settings?.wonderRules?.[(teamData?.wonderState?.step || 1) + 1]?.goal

  const buildingCurrentPoints = teamPoints ? (teamPoints - teamGoalAdded) : 0
  yield sendEvent('Phase2BuildingInvested', {
    buildingType: buildingType,
    investValue: investedFlouz,
    teamId: teamId,
    playerFullOnBoarding: playerFullOnBoarding,
    teamName: teamData?.name || "",
    evolve: (buildingCurrentPoints + investedFlouz) >= buildingGoal
  })
}

export function* phase2InvestAllInBuilding(command) {
  const campaign = yield campaignGet()

  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)

  const dateNow = new Date(Date.now()).toISOString()
  const campaignSettings = yield campaignGet('settings')
  const campaignEnded = campaignSettings?.['endAt'] < dateNow

  const playerFullOnBoarding = campaignEnded && !teamId
  const teamId = command?.teamId
  const teamData = playerFullOnBoarding ?
    yield campaignGet(`states/${user?.id}/onBoardingGameState`)
    : yield campaignGet(`teams/${teamId}`)

  const userState = yield stateGet(command.emitter)
  const buildingType = command?.buildingType
  const userFlouz = userState?.flouz

  const investValue = userFlouz

  if (!userFlouz || !teamId) return

  yield sendEvent('Phase2BuildingInvested', {
    buildingType: buildingType,
    investValue: investValue,
    teamId: teamId,
    playerFullOnBoarding: playerFullOnBoarding,
    teamName: teamData?.name || ""
  })
  
}
