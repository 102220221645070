import { campaignGet, sendEvent, stateGet } from '../../../../saga-tools/Data.effects'
import { getNewId }                         from '../../../../saga-tools/Random.effects'

export function* challengePlayer(command) {
  const opponentId = command.playerChallenged
  const campaignSettings = yield campaignGet('settings')
  const supportOldV1 = !(campaignSettings.phase === 1 && !campaignSettings?.useThemes)
  const challengeId = yield getNewId()
  yield sendEvent("PlayerChallenged", {
    challengeId: challengeId,
    session: {
      invited: false,
      status: "started",
      opponentUid: opponentId,
      type: command.moduleType,
      level: command.level,
      theme: command?.theme,
      moduleName: supportOldV1 ? command?.moduleName : command?.moduleType,
      result: command?.result
    },
  })
  if (!opponentId.includes('bot')){
    const opponentState = yield stateGet(opponentId)
    const opponentLang = opponentState?.lang
    /*yield sendEvent('SendEmail', {
      lang: opponentLang || 'fr',
      from: command.emitter,
      to: opponentId,
      payload: {
        LINK: `challenges/${command.moduleType}/${command.level}/${challengeId}`,
        SUBJECT: yield emailTrad(opponentLang || "fr", 'emails.quizInvite.subject'),
        MESSAGE: yield emailTrad(opponentLang || "fr", 'emails.quizInvite.message'),
        ACTION: yield emailTrad(opponentLang || "fr", 'emails.quizInvite.action'),
      },
    })*/
  }
}

export function* endAllChallenges(){
  const allSessions = yield campaignGet(`sessions`)
  const allPlayersIds = Object.keys(allSessions)
  allPlayersIds.reduce((acc, id) => {

  })
}
