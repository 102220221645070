import { campaignGet, sendEvent, stateGet } from '../../../../saga-tools/Data.effects'
import { createFunctionalError }            from '../../../../saga-tools/Errors'
import { profileErrors }                    from '../../../Profile/Profile.errors'
import { currentActionPointsAmount }        from '../../../ActionPoints/ActionPoints.helpers'
import { ActionPointsErrors }               from '../../../ActionPoints/ActionPoints.errors'

export function* updatePlayerPosition(command){
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)
  const valuePA = command.typeTile === 'river' ? 2 : command.typeTile === 'trip' ? 0 : 1
  const userState = yield stateGet(command.emitter)
  const actionPoints = yield currentActionPointsAmount()
  if (actionPoints < valuePA){
    throw createFunctionalError(ActionPointsErrors.notEnoughActionPoints)
  }

  if(userState){
    yield sendEvent("PlayerPositionUpdated", {
      playerPosition: command.playerPosition,
      phase: command?.phase,
      currentWorld: command?.currentWorld,
    })
    yield sendEvent('ActionPointsSet', {
      points: valuePA,
    })
  }
}

export function* updateFogMap(command){
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)
  const userState = yield stateGet(command.emitter)
  if(userState){
    yield sendEvent("FogMapUpdated", {
      fogMap: command.fogMap,
      phase: command?.phase,
      currentWorld: command?.currentWorld,
    })
  }
}

export function* checkFinalDestinationIsDisplayed(command){
  const user = yield getUserById(command.emitter)
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)
  const userState = yield stateGet(user.id)
  if(userState){
    const newFogMap = JSON.parse(userState?.map?.fogMap)
    if(newFogMap?.[18]?.[9] === 0){
      newFogMap[18][9] = 1
      yield sendEvent("FogMapUpdated", {
        fogMap: newFogMap
      })
    }
  }
}
