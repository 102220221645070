import { campaignSet } from '../../../../saga-tools/Data.effects'

export function * playerChallenged (event) {
  yield campaignSet(
    `sessions/${event.target}`,
    (session) => ({
      ...session,
      id: event.target,
      challenges: {
        ...session.challenges,
        [event.challengeId]: {
          status: 'ended',
          invited: false,
          opponentUid: event.session.opponentUid,
          type: event.session.type,
          level: event.session.level,
          timeStamp: event.timeStamp,
          ...!!(event?.session?.theme) && { theme: event?.session?.theme },
          result: event?.session?.result,
          ...!!(event?.session?.moduleName) && { moduleName: event?.session?.moduleName },
        }
      }
    } )
  )
  yield campaignSet(
    `sessions/${event.session.opponentUid}`,
    (session) => ({
      ...session,
      id: event.target,
      challenges: {
        ...session.challenges,
        [event.challengeId]: {
          status: 'started',
          invited: true,
          opponentUid: event.target,
          type: event.session.type,
          level: event.session.level,
          timeStamp: event.timeStamp,
          ...!!(event?.session?.theme) && { theme: event?.session?.theme },
          opponentResult: event?.session?.result,
          ...!!(event?.session?.moduleName) && { moduleName: event?.session?.moduleName },
        }
      }
    })
  )
  
  yield campaignSet(`states/${event.target}/pendingChallenges`, increment)
  yield campaignSet(`states/${event.target}/challengesCount`, increment)
  
  if (event.session.invited) {
    yield campaignSet(`states/${event.target}/receivedChallenges`, increment)
    yield campaignSet(`states/${event.target}/challengesCount`, increment)
    yield campaignSet(`states/${event.target}/invitedChallenges`, increment)
  }
}

export const increment = (count) => (count ? count + 1 : 1)
export const decrement = (count) => (count ? count - 1 : 0)
