import React, { useRef }                              from 'react'
import { ScrollView, TouchableWithoutFeedback, View } from 'react-native'
import styled                                         from '@emotion/native'
import { useScreenDimensions }                        from '../../hooks/useScreenDimensions'
import { ActionButton, CloseButton }                  from '../Button'
import { Title1 }                                     from '../Texts'
import { colors }                                     from '../../configs/colors'
import { MOBILE_HEADER_SIZE }                         from '../../configs/sizes'
import InfoButton                                     from '../../../assets/info-button.svg'

export const DefaultModal = ({
  backgroundColor = colors.primary,
  title,
  color = colors.lightText,
  children,
  onPress,
  maxHeight,
  fixedHeight,
  withShadow,
  closeButtonColor = colors.secondary,
  noBorder,
  contentBackgroundColor,
  crossSize,
  borderRadius,
  modalResult,
  mobile,
  orientation,
  noScroll,
  infoButton,
  hub,
  noHeader,
  ...props
}) => {
  const root = useRef(null)
  const { width, height } = useScreenDimensions(root)
  return (
    <WrapperModal hub={hub} ref={root} {...props} {...{ withShadow }} >
      <Modal
        {...{
          width,
          height,
          backgroundColor,
          maxHeight,
          fixedHeight,
          withShadow,
          noBorder,
          contentBackgroundColor,
          borderRadius,
          noHeader
        }}
      >
        {title && <TitleBar {...{ backgroundColor, withShadow, borderRadius }}>
          {
            infoButton && <InfosButton infoButton={infoButton}/>
          }
          <TitleWrapper>
            <Title {...{ color }}>{title || 'Titre'}</Title>
          </TitleWrapper>
          {onPress && (
            <CloseButtonDefaultModal
              color={closeButtonColor}
              iconColor="white"
              iconSize={crossSize / 2 || 15}
              size={crossSize || 30}
              noShadow
              {...{ onPress }}
            />
          )}
        </TitleBar>}
        <ScrollView horizontal={false} style={{ overflowY: noScroll ? 'hidden' : 'unset' }}>{children}</ScrollView>
      </Modal>
    </WrapperModal>
  )
}

export const InfosButton = ({ infoButton }) => {
  return <WrapperInfosButton>
    <TouchableWithoutFeedback onPress={infoButton}>
      <View>
        <InfoButton width={25} height={25}/>
      </View>
    </TouchableWithoutFeedback>
  </WrapperInfosButton>
}

const WrapperInfosButton = styled.View({
  position: 'absolute',
  top: '50%',
  left: 10,
  transform: [{ translateY: '-50%' }]
})

const WrapperModal = styled.View(({ withShadow, hub }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  boxShadow: withShadow && '3px 3px 5px rgba(94, 104, 121, 0.945), -3px -3px 7px #FFFFFF',
  borderRadius: withShadow && 5,
  marginTop: hub && 150,
}))

export const Modal = styled.View(
  {
    maxWidth: 845,
    flexDirection: 'column',
  },
  ({
    width,
    height,
    backgroundColor,
    maxHeight,
    fixedHeight,
    noBorder,
    contentBackgroundColor,
    borderRadius,
    noHeader
  }) => ({
    backgroundColor: contentBackgroundColor || colors.defaultBackground,
    width: width * 0.95,
    maxHeight: maxHeight ? maxHeight : noHeader ? height * .9 : (height - MOBILE_HEADER_SIZE) * 0.85,
    minHeight: fixedHeight ? (height - MOBILE_HEADER_SIZE) * 0.85 : 200,
    borderColor: !noBorder && backgroundColor,
    borderWidth: !noBorder && 2,
    borderRadius: borderRadius || 5,
    overflow: 'hidden'
  }),
  ({ withShadow }) =>
    withShadow && {
      borderWidth: 0,
    }
)

const TitleBar = styled.View(
  {
    position: 'relative',
    minHeight: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -1,
    marginRight: -1,
  },
  ({ backgroundColor, borderRadius }) => ({
    backgroundColor: backgroundColor,
    borderTopRightRadius: borderRadius || 0,
    borderTopLeftRadius: borderRadius || 0,
  }),
  ({ withShadow }) =>
    withShadow && {
      borderTopRightRadius: 5,
      borderTopLeftRadius: 5,
    }
)

const Title = styled(Title1)({
  textTransform: 'uppercase',
})

export const CloseButtonDefaultModal = styled(CloseButton)(({ mobile, top, right }) => ({
  position: 'absolute',
  right: right ? right : mobile ? 2 : 5,
  top: top ?? 2,
  zIndex: 11
}))

export const BubbleHelp = ({
  hoverColor,
  children,
  mobile,
  title,
  ...props
}) => {
  const ref = useRef(null)
  const { width } = useScreenDimensions(ref)
  
  return (
    <WrapperBubble ref={ref} {...props}>
      <BubbleContent mobile={width < 760} width={width}>
        <BubbleTitle hoverColor={hoverColor}>
          <Title>{title}</Title>
        </BubbleTitle>
        {children}
      </BubbleContent>
      <BubbleNotch/>
    </WrapperBubble>
  )
}

const WrapperBubble = styled.View({
  position: 'absolute',
})

const BubbleContent = styled.View(
  {
    width: 250,
    minHeight: 250,
    boxShadow: '3px 3px 5px rgba(94, 104, 121, 0.945), -3px -3px 7px #FFFFFF',
    backgroundColor: colors.defaultBackground,
    borderRadius: 5,
  },
  ({ mobile, width }) => ({
    width: mobile ? width * 0.95 : 250,
  })
)

const BubbleTitle = styled.View(
  {
    paddingTop: 10,
    paddingBottom: 10,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  ({ hoverColor }) => ({
    backgroundColor: hoverColor || colors.lightBlue,
  })
)

const BubbleNotch = styled.View({
  position: 'absolute',
  bottom: -7,
  right: 20,
  width: 15,
  height: 15,
  backgroundColor: colors.defaultBackground,
  transform: [{ rotate: '45deg' }],
  boxShadow: 'rgba(94, 104, 121, 0.745) 2px 2px 2px',
})

export const ModulesModal = ({
  noButton,
  backgroundColor,
  color,
  onPress,
  buttonText,
  children,
  actionButton,
  padding,
  mobile,
  pnj,
  customPositionCross,
  minWidth,
  noShadow,
  pnjMod,
  textFont,
  secondButtonText,
  secondAction,
  useThemes,
  secondActionButton,
  phase2,
  width,
  center,
  crossColor,
  ...props
}) => {
  return (
    <WrapperModulesModal {...props}>
      <ModulesModalStyle padding={padding} mobile={mobile} minWidth={minWidth} contentBackgroundColor={backgroundColor}
                         noShadow={noShadow} pnjMod={pnjMod} width={width}>
        {onPress && (
          <CloseButtonDefaultModal
            color={crossColor || color}
            iconColor="white"
            iconSize={18}
            size={30}
            noShadow
            crossColor={crossColor}
            mobile={mobile}
            style={{ top: customPositionCross || 20, right: customPositionCross || 30 }}
            {...{ onPress }}
          />
        )}
        <WrapperContent padding={padding} center={center}>
          {children}
          {!noButton ? (
            <WrapperButton secondButton={!!(secondActionButton && secondAction)} mobile={mobile} phase2={phase2}>
              <ActionButton
                style={{ width: 'max-content' }}
                backgroundColor={color}
                color="white"
                onPress={actionButton}
                pnj={pnj}
                mobile={mobile}
                textFont={textFont}
              >
                {buttonText}
              </ActionButton>
              {
                secondActionButton && <ActionButton
                  style={{ width: 'max-content' }}
                  backgroundColor={phase2 ? colors.white : color}
                  color={phase2 ? colors.secondary : 'white'}
                  onPress={secondAction}
                  pnj={pnj}
                  mobile={mobile}
                >
                  {secondButtonText}
                </ActionButton>
              }
            </WrapperButton>
          ) : null}
        </WrapperContent>
      </ModulesModalStyle>
    </WrapperModulesModal>
  )
}

export const ModulesModalV2 = ({
  noButton,
  backgroundColor,
  color,
  onPress,
  buttonText,
  children,
  secondChildren,
  actionButton,
  padding,
  mobile,
  pnj,
  crossTop,
  crossRight,
  ...props
}) => {
  return (
    <WrapperModulesModal {...props}>
      <ModulesModalStyle mobile={mobile} {...props}>
        <WrapperContent padding={padding}>
          {onPress && (
            <CloseButtonDefaultModal
              color={color}
              iconColor="white"
              iconSize={18}
              size={30}
              noShadow
              mobile={mobile}
              style={{ top: 20, right: 30 }}
              {...{ onPress }}
            />
          )}
          {children}
          {!noButton ? (
            <WrapperButton>
              <ActionButton
                style={{ width: 'max-content' }}
                backgroundColor={color}
                color="white"
                onPress={actionButton}
                pnj={pnj}
                mobile={mobile}
              >
                {buttonText}
              </ActionButton>
            </WrapperButton>
          ) : null}
        </WrapperContent>
        {secondChildren}
      </ModulesModalStyle>
    </WrapperModulesModal>
  )
}

const WrapperModulesModal = styled.View({
  alignItems: 'center',
})

const WrapperContent = styled.View(({ padding, center }) => ({
  paddingRight: padding ? padding : 50,
  paddingLeft: padding ? padding : 50,
  paddingBottom: padding ? padding : 50,
  position: 'relative',
  textAlign: center && 'center'
}))

const ModulesModalStyle = styled(Modal)(({ padding, mobile, minWidth, noShadow, pnjMod, width }) => ({
  maxWidth: mobile ? '100%' : 600,
  minWidth: minWidth ? minWidth : 200,
  width: !mobile && (width || 'auto'),
  borderRadius: 18,
  border: 'none',
  boxShadow: noShadow ? 'none'
    : 'inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)',
  paddingTop: 30,
  paddingRight: padding || 0,
  paddingLeft: padding || 0,
  paddingBottom: padding || 0,
  overflow: pnjMod ? 'unset' : 'hidden'
}))

const WrapperButton = styled.View(({ secondButton, mobile, phase2 }) =>
  ({
    alignItems: 'center',
    paddingTop: 30,
    zIndex: 11,
    display: secondButton && 'flex',
    flexDirection: phase2 ? mobile ? 'row-reverse'
      : 'column'
      : secondButton && mobile ? 'column'
        : 'row',
    justifyContent: secondButton ? 'space-between' : 'center',
    marginLeft: !mobile && secondButton && 10,
    marginRight: !mobile && secondButton && 10
  }))

const TitleWrapper = styled.View({
  marginRight: 40
})
