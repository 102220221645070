import React, { useEffect, useRef, useState } from 'react'
import styled                                 from '@emotion/native'
import { colors }               from '@civitime/library/storybook/configs/colors'
import { CloseButtonComponent } from './CloseButton'
import { QuestionComponents }   from './QuestionsComponents'
import { ResultsScreen }        from './ResultsScreen'
import { Outro }                from './ContentScreens'
import { Animated }             from 'react-native'

export const QuestionsScreen = ({
  screenDimensions, mobile, onClose, data, sendResult, result, playerProfile,
  updateQuestionsTimeStamps, knowMoreData,
  opponentProfile, opponentResult, contentTranslations, timeStampStart,
  questionsTimeStamps, quizBoss, phase, botProfile, isSafari
}) => {
  const [showResults, setShowResults] = useState(false)
  const [showMemo, setShowMemo] = useState(false)
  const [memoSeen, setSeenMemo] = useState(false)
  const [hideModuleContentOnQuit, setHideModuleContentOnQuit] = useState(false)
  const thunderEndedAnimationScale = useRef(new Animated.Value(1)).current

  const launchLoaderModuleEnded = () => {
    Animated.sequence([
      Animated.timing(
        thunderEndedAnimationScale,
        {
          toValue: 1.2,
          duration: 300,
        }),
      Animated.timing(
        thunderEndedAnimationScale,
        {
          toValue: 0.8,
          duration: 300,
        }),
      Animated.timing(
        thunderEndedAnimationScale,
        {
          toValue: 1.3,
          duration: 200,
        }),
      Animated.timing(
        thunderEndedAnimationScale,
        {
          toValue: 1,
          duration: 400,
        })
    ]).start(launchLoaderModuleEnded)
  }

  useEffect(() => {
    if (!hideModuleContentOnQuit) return
    launchLoaderModuleEnded()
  }, [hideModuleContentOnQuit])


  return <WrapperQuestionsScreen screenDimensions={screenDimensions}>
    <BackgroundQuestionsScreen screenDimensions={screenDimensions}
                               source={require('../Assets/backgroud-game.png')}
                               alt="background questions"/>
    <GameLogo screenDimensions={screenDimensions}
              source={require('../Assets/logo.png')}
              alt="logo duel quiz"
              mobile={mobile}/>
    {
      !showResults &&
      <CloseButtonComponent screenDimensions={screenDimensions} mobile={mobile} onClose={onClose} result={result}
                            data={data}/>
    }
    {
      showResults && !showMemo ?
        <ResultsScreen screenDimensions={screenDimensions} mobile={mobile} result={result}
                       playerProfile={playerProfile} opponentResult={opponentResult} timeStampStart={timeStampStart}
                       opponentProfile={opponentProfile}
                       onClose={() => {
                         if (quizBoss) {
                           onClose({
                             win: (data?.length === Object.keys(result)?.length),
                             answers: {
                               questions: result,
                               questionCount: Object.keys(result)?.length,
                               questionsTimeStamps: questionsTimeStamps
                             },
                             goodAnswers: Object.values(result || {})?.filter(Boolean)?.length,
                             timeStampStart: timeStampStart,
                             timeStampEnd: new Date()
                           })
                           return
                         }
                         setShowMemo(true)
                       }} data={data}
                       quizBoss={quizBoss} phase={phase}
                       questionsTimeStamps={questionsTimeStamps} botProfile={botProfile}/>
        :
        showResults && showMemo && !memoSeen ? <Outro setHideModuleContentOnQuit={setHideModuleContentOnQuit} knowMoreData={knowMoreData} height={screenDimensions.height}
                                                      mobile={screenDimensions.mobile}
                                                      isSafari={isSafari}
                                                      onPress={() => {
                                                        setSeenMemo(true)
                                                        onClose({
                                                          win: (data?.length === Object.keys(result)?.length),
                                                          answers: {
                                                            questions: result,
                                                            questionCount: Object.keys(result)?.length,
                                                            questionsTimeStamps: questionsTimeStamps
                                                          },
                                                          goodAnswers: Object.values(result || {})?.filter(Boolean)?.length,
                                                          timeStampStart: timeStampStart,
                                                          timeStampEnd: new Date()
                                                        })
                                                        setShowMemo(false)
                                                      }} data={data}
          />
          : <QuestionComponents screenDimensions={screenDimensions} mobile={mobile} data={data}
                                contentTranslations={contentTranslations} phase={phase}
                                updateQuestionsTimeStamps={updateQuestionsTimeStamps}
                                sendResult={sendResult} result={result} showResults={() => setShowResults(true)}
          />
    }
    {
      hideModuleContentOnQuit &&
      <WrapperQuizEndedLoader screenDimensions={screenDimensions}>
        <QuizBackground source={require('../Assets/backgroud-game.png')} alt='background' resizeMode='contain'/>
        <QuizLogo screenDimensions={screenDimensions} source={require('../Assets/logo-mobile-start-screen.png')}
                  alt='background' resizeMode='contain'/>
        <WrapperThunder
          animation={thunderEndedAnimationScale}
          screenDimensions={screenDimensions}
        >
          <ThunderAnimated source={require('../Assets/thunder-orange.png')}
                           alt='thunder'
                           resizeMode='contain'
          />
        </WrapperThunder>
      </WrapperQuizEndedLoader>
    }
  </WrapperQuestionsScreen>
}

const WrapperThunder = styled(Animated.View)(({animation, screenDimensions}) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: screenDimensions.width * 0.1,
  height: (screenDimensions.width * 0.1) * 1.6,
  transform: [{translateX: '-50%'}, {translateY: '-50%'}, {scale: animation}],
  zIndex: 4
}))

const ThunderAnimated = styled.Image({
  width: '100%',
  height: '100%',
})

const QuizLogo = styled.Image(({screenDimensions}) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: screenDimensions.width * 0.4,
  height: (screenDimensions.width * 0.4) * 0.6,
  zIndex: 3,
  transform: [{translateX: '-50%'}, {translateY: '-50%'}]
}))

const QuizBackground = styled.Image({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 2
})

const WrapperQuizEndedLoader = styled.View(({screenDimensions}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: screenDimensions.width,
  height: screenDimensions.height,
  backgroundColor: 'rgb(238, 238, 238)'
}))

const WrapperQuestionsScreen = styled.View(({screenDimensions}) => ({
  width: screenDimensions.width,
  height: screenDimensions.height,
  backgroundColor: colors.whisper
}))

const BackgroundQuestionsScreen = styled.Image(({screenDimensions}) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: screenDimensions.width,
  height: screenDimensions.height,
  opacity: .5
}))

const GameLogo = styled.Image(({screenDimensions, mobile}) => ({
  position: 'absolute',
  left: (screenDimensions.width - (mobile ? (screenDimensions.width / 2) : (screenDimensions.width / 5))) / 2,
  top: 25,
  width: mobile ? (screenDimensions.width / 2) : (screenDimensions.width / 5),
  height: (mobile ? (screenDimensions.width / 2) : (screenDimensions.width / 5)) * 0.18,
}))


