import React, { useRef, useState }   from 'react'
import styled                        from '@emotion/native'
import { useScreenDimensions }       from '@civitime/library/storybook/hooks/useScreenDimensions'
import { StartScreen }               from './Components/StartScreen'
import { QuestionsScreen }           from './Components/QuestionsScreen'
import { TranslationModuleProvider } from './translations/translate'
import { Intro }                     from './Components/ContentScreens'
import DeviceDetector from "device-detector-js"

export const DuelQuizGame = ({
  onClose,
  data,
  playerProfile,
  opponentProfile,
  opponentResult,
  contentTranslations,
  lang,
  phase,
  quizBoss,
  botProfile,
  timeStampStart,
  translateUrl,
  knowMoreData
}) => {
  const root = useRef(null)
  const {width, height, mobile} = useScreenDimensions(root)
  const [isInitialized, setIsInitialized] = useState(false)
  const [displayIntro, setDisplayIntro] = useState(false)
  const [result, setResult] = useState({})
  const [questionsTimeStamps, setQuestionsTimeStamps] = useState({})
  const isSafari = getBrowserInfos()?.os?.toLowerCase()?.includes("ios")
  return <MainWrapper width={width} height={height} ref={root}>
    <TranslationModuleProvider lang={lang} translateUrl={translateUrl}>
      {
        !isInitialized ? <StartScreen
          quizBoss={quizBoss}
          screenDimensions={{ width: width, height: height }}
          initialized={() => {
            if(quizBoss){
              setIsInitialized(true)
              return
            }
            setDisplayIntro(true)
          }}
          mobile={mobile}
        />
        : <QuestionsScreen
          quizBoss={quizBoss}
          screenDimensions={{ width: width, height: height, mobile: mobile }}
          onClose={onClose}
          data={data}
          mobile={mobile}
          result={result}
          timeStampStart={timeStampStart}
          playerProfile={playerProfile}
          opponentProfile={opponentProfile}
          opponentResult={opponentResult}
          phase={phase}
          knowMoreData={knowMoreData}
          isSafari={isSafari}
          botProfile={botProfile}
          contentTranslations={contentTranslations}
          updateQuestionsTimeStamps={(e) => setQuestionsTimeStamps(e)}
          questionsTimeStamps={questionsTimeStamps}
          sendResult={(id, newResult) => setResult((result) => ({
            ...result,
            [id]: newResult
          }))}/>
      }
      {
        displayIntro && height && <IntroWrapper>
          <Intro data={data} quit={onClose}
                 onPress={() => {
            setIsInitialized(true)
            setDisplayIntro(false) }} height={height} isSafari={isSafari}/>
        </IntroWrapper>
      }
    </TranslationModuleProvider>
  </MainWrapper>
}

const MainWrapper = styled.View(({width, height}) => ({
  width: width,
  height: height,
  position: "relative"
}))

const IntroWrapper = styled.View({
  position: 'absolute',
  left: 0,
  bottom: 0,
  top: 0,
  right: 0
})

export const getBrowserInfos = () => {
  const deviceDetector = new DeviceDetector()
  const userAgent = navigator.userAgent
  const device = deviceDetector.parse(userAgent)
  
  const os = `${device.os.name} ${device.os.version}`
  const browserName = device.client.name
  const browserVersion = device.client.version
  
  return {
    browser: browserName,
    version: browserVersion,
    os     : os
  }
}