import { runSaga, stdChannel } from 'redux-saga'
import { dataMiddleware, EVENT_TREATED } from './Data.effects'
import { Subject } from 'rxjs'

export const REDUCER_ENDED = 'REDUCER_ENDED'
export function createReducerRawRunner (reducer, dataAccessor) {
  const inChannel = stdChannel()
  const outStream$ = new Subject()

  const IO = {
    channel: inChannel,
    getState () {
      throw new Error("getState in saga runner shouldn't be called.")
    },
    dispatch (event) {
      if (event?.type === REDUCER_ENDED) {
        outStream$.next(event)
        return
      }
      throw new Error('Reducers should not dispatch events')
    },
    effectMiddlewares: [dataMiddleware(dataAccessor)]
  }

  const task = runSaga(IO, reducer)

  return {
    stream$: outStream$,
    emit (command) {
      inChannel.put(command)
    },
    close () {
      inChannel.close()
      outStream$.complete()
      task.cancel()
    }
  }
}
