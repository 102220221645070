import React from "react"
import styled from "@emotion/native"
import { StyleSheet, Dimensions } from "react-native"
import { colors } from "../../configs/colors"
import { SearchIcon } from "../Icons"

export const InputRow = ({ children }) => {
  const { width, height } = Dimensions.get("window")

  if (width > height ? height < 760 : width < 760) {
    return <MobileInputRow>{children}</MobileInputRow>
  } else {
    return <DesktopInputRow>{children}</DesktopInputRow>
  }
}

const DesktopInputRow = styled.View({
  paddingTop: 40,
  minWidth: 300,
})

const MobileInputRow = styled.View({
  paddingTop: 10,
  height: 55,
  minWidth: 250,
})

export const Input = ({ icon, disabled, secondIcon, ...props }) => {
  return (
    <InputContainer {...{ disabled }}>
      {icon}
      <InputText {...{ disabled }} {...props} />
      {
        secondIcon
      }
    </InputContainer>
  )
}

const InputContainer = styled.View(
  {
    alignItems: "center",
    alignSelf: "stretch",
    borderBottomColor: colors.inputBorder,

    flex: 1,
    flexDirection: "row",
    minHeight: 30,
  },
  ({ disabled }) => ({
    borderBottomWidth: disabled ? 0 : StyleSheet.hairlineWidth,
  })
)

export const InputText = styled.TextInput({
  color: colors.text,
  flex: 1,
  paddingHorizontal: 15,
  paddingVertical: 5,
  fontFamily: "Raleway_500Medium",
  fontWeight: "500",
  fontSize: 15,
  lineHeight: 18,
})

export const InputCardWrapper = ({ children, ...props }) => {
  return <WrapperInputCard {...props}>{children}</WrapperInputCard>
}

export const InputCard = ({ placeholder, withIcon, ...props }) => {
  return (
    <>
      {withIcon && (
        <InputSearchIcon width={17.5} height={17.5} color={colors.text} />
      )}
      <TextInputStyle {...{ placeholder, withIcon }} {...props} />
    </>
  )
}

const WrapperInputCard = styled.View({
  justifyContent: "center",
})

const InputSearchIcon = styled(SearchIcon)({
  position: "absolute",
  left: 12,
  top: 8
})

const TextInputStyle = styled.TextInput(
  {
    backgroundColor: colors.defaultBackground,
    boxShadow:
      "inset 3px 3px 5px rgba(94, 104, 121, 0.692), inset -3px -3px 7px #FFFFFF",
    borderRadius: 15,
    placeholderTextColor: "rgba(74, 74, 74, 0.5)",
    color: colors.text,
    paddingHorizontal: 14,
    paddingVertical: 7,
  },
  ({ withIcon }) =>
    withIcon && {
      paddingLeft: 37,
    }
)
