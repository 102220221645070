import {collection, collectionGroup, database, storage} from "../DatabaseModel"

type Lang = string
type ModuleId = string
type Storage<T> = T
type UserId = string
type WorldId = string
type ClientId = string
type CampaignId = string
type TeamId = string
type EmailHash = string
type PaletteId = string
type ItemId = string
type AvatarItemCategory = "faces" | "hairs" | "clothes" | "accessories"
type AvatarItemType = string
type InvitesId = string
type LeaderboardId = string
type LevelId = string
type ModuleName = any

const campaignsKey = "campaigns"
const profilesKey = "profiles"
const clientsKey = "clients"
const usersKey = "users"
const emailsIndexKey = "emailsIndex"
const textsKey = "texts"
const statesKey = "states"
const sessionKey = "sessions"
const avatarLibraryKey = "avatarLibrary"
const colorPaletteKey = "avatarColorPalettes"
const inventoriesKey = "avatarInventories"
const teamsKey = "teams"
const invitesKey = "invites"
const leaderboards = "leaderboards"
const contentKey = "content"
const commandsKey = "commands"
const eventsKey = "event"
const worldsKey = "worlds"

const campaigns = collection<CampaignId, Campaign>({
    [worldsKey]: collection<WorldId, World>(),
    [statesKey]: collection<UserId, State>(),
    [teamsKey]: collection<TeamId, Team>(),
    [invitesKey]: collection<InvitesId, Invites>(),
    [sessionKey]: collection<UserId, Modules | Challenges>(),
    [contentKey]: collection<ModuleName,
        QuizzesContent | MasterMindContent | MemoryContent | QrCodes>(),
    [leaderboards]: collection<LeaderboardId, LeaderBoard>(),
    [textsKey]: storage<Lang, ModulesTexts>('ct-campaigns.civitimeapp.com')
})

const commands = collection<CampaignId, Campaign>({
    users: collection<UserId, Commands>()
})

export const phase1Database = database({
    [clientsKey]: collection<ClientId, Client>({
        [campaignsKey]: campaigns,
        [profilesKey]: collection<UserId, Profile>(),
        [inventoriesKey]: collection<UserId, Inventory>()
    }),
    [campaignsKey]: collectionGroup(campaigns),
    [usersKey]: collection<UserId, AuthUser>(),
    [emailsIndexKey]: collection<EmailHash, UserIndexValue>(),
    [textsKey]: storage<Lang, Texts>("ct-profiles"),
    [avatarLibraryKey]: collection<ItemId, Item>({}),
    [colorPaletteKey]: collection<PaletteId, Palette>(),
    [commandsKey]: collection<ClientId, Client>({
        campaigns: commands
    }),
    [eventsKey]: collection<ClientId, Client>({
        campaigns: commands
    }),
    [textsKey]: storage<Lang, any>('ct-campaigns.civitimeapp.com')
})

type Challenges = {
    [Key in string]: Challenge
}

type Commands = {
    [Key in string]: any
}

type Challenge = {
    invited: boolean
    status: string
    opponentUid: UserId
    type: ModuleName
    timeStamp: string
    win: boolean
    result: number
    id: string
}

type LevelDone = {
    id: string
    position: {
        line: number
        tile: number
    }
    destination?: {
        line: number
        tile: number
    }
    timeStamp: string
}

type LevelFailed = {
    id: string
    timeStamp: string
}

type LevelContent = {
    name: string
    image?: string
}

type Modules = {
    [Key in ModuleName]: {
        name: string
        levelsDone: {
            [Key in LevelId]: LevelDone
        }
        nextLevel: string
    }
}

type QuizzesContent = {
    questionCount: number
    levels: {
        [Key in LevelId]: LevelContent
    }
}

type MemoryContent = {
    levels: {
        [Key in LevelId]: LevelContent
    }
}

type QrCodes = any

type MasterMindContent = {
    levels: {
        [Key in LevelId]: LevelContent
    }
}

type ModulesTexts = {
    [key in ModuleName]: {
        levels: {
            [Key in LevelId]: LevelContent
        }
    }
}

type AppTexts = {}

type UserIndexValue = { value: string }
type Texts = {
    [k in ModuleId]: TranslationTree
}
type TranslationTree = {
    [k in string]: string | TranslationTree
}
type Profile = {
    name: string
    avatar: {
        colors: Map<PaletteId, number>
        items: ItemId[]
    }
}
type World = any

type Client = {}

type Campaign = {
    texts: Storage<Texts>
    settings: {
        maxTeamMembers: number
        langs: string[]
        map: {
            fogMapDefault: string
            mapDefaultPosition: {
                line: number
                tile: number
            }
            trips: {
                [k in string]: {
                    start: {
                        line: number
                        tile: number
                    }
                    end: {
                        line: number
                        tile: number
                    }
                }
            }
        }
        actionPointsPerDay: number
        maxActionPoints: number
        startAt: string
        endAt: string
        modules: {
            [k in string]: string
        }
    }
}
type Team = {
    name: string
    members: UserId[]
    points: number
    logo: {
        shape: "square" | "triangle" | "round"
        color: string
        text: string
    }
    contributions: {
        [Key in UserId]: number
    }
}
type Invites = {
    teamsId: TeamId[]
}

export type LeaderBoard = {
    [k in string]: number
}

export type AuthUser = {
    email: string
    name: string
    password: string
    acceptedCGU: string
    acceptNotifications: boolean
    acceptEmails: boolean
    notificationToken?: string
    ownedAccounts?: string[]
    isAdmin?: boolean
}

export type State = {
    lang: string
    map: {
        playerPosition: {
            line: number
            tile: number
        }
        fogMap: number[][]
    }
    actionPoints: {
        spentActionPoints: number
        lastPASpent: string
    }
    challengesCount: number
    pendingChallenges: number
    invitedChallenges: number
    fragments: number
    finalDestinationDiscovered: boolean
    finalDestinationModalSeen: boolean
    campaignEndedModalSeen: boolean
}

export type Item = {
    id: ItemId
    label: string
    price: number
    preview: string
    type: AvatarItemType
    category: AvatarItemCategory
    layers: {
        color: PaletteId
        zIndex: number
        sprite: string
    }
}

export type Palette = {
    id: PaletteId
    colors: Map<AvatarItemType, string>
}

export type Inventory = {
    id: UserId
    items: ItemId[]
}
