import React           from 'react'
import styled          from '@emotion/native'
import { CloseButton } from '@civitime/library/storybook/stories/Button'
import { colors }      from '@civitime/library/storybook/configs/colors'
import { useTranslator }              from '../translations/translate'

export const CloseButtonComponent = ({ screenDimensions, mobile, onClose, result, data }) => {
  const {t} = useTranslator()
  return <WrapperButtonToClose mobile={mobile}
                               screenDimensions={screenDimensions}>
    <CloseButton color={colors.module}
                 size={25}
                 iconColor={colors.lightBackground}
                 iconSize={15}
                 onPress={() => {
                   return onClose({ ...result, win: (data?.length === Object.keys(result)?.length) })
                 }}
    />
    <TextCloseButton>{t('app.closeButton')}</TextCloseButton>
  </WrapperButtonToClose>
}

const WrapperButtonToClose = styled.View(({ mobile, screenDimensions }) => ({
  position: 'absolute',
  top: mobile ? 10 : 20,
  right: mobile ? 10 : (screenDimensions.width * 0.10)
}))

const TextCloseButton = styled.Text({
  position: 'absolute',
  color: colors.module,
  fontSize: 10,
  fontWeight: 'bold',
  bottom: -15,
  left: -5
})
