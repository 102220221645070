import React, { useRef, useState }            from 'react'
import { brand as isMobileApp }               from 'expo-device'
import Done                                   from '../../../assets/done.svg'
import { useHovered }                         from '../../hooks/useHovered'
import styled                                 from '@emotion/native'
import { Animated, TouchableWithoutFeedback } from 'react-native'
import { colors }                             from '../../configs/colors'

// Icons
import Civiflouz                  from '../../../assets/civiflouz.svg'
import CiviflouzRound             from '../../../assets/civiflouz-round.svg'
import Construction               from '../../../assets/construction.svg'
import Cross                      from '../../../assets/cross.svg'
import Cubes                      from '../../../assets/cubes.svg'
import Drop                       from '../../../assets/drop.svg'
import InterrogationPoint         from '../../../assets/interrogation-point.svg'
import Helper                     from '../../../assets/interrogation-point.svg'
import Padlock                    from '../../../assets/padlock.svg'
import RightArrow                 from '../../../assets/right-arrow.svg'
import Settings                   from '../../../assets/settings.svg'
import Tap                        from '../../../assets/tap.svg'
import Thunder                    from '../../../assets/thunder.svg'
import ThunderRound               from '../../../assets/thunder-round.svg'
import { useIsActive }            from '../../hooks/useIsActive'
import Leaderboard                from '../../../assets/leaderboard.svg'
import Focus                      from '../../../assets/focus.svg'
import WorldsMenu                 from '../../../assets/world-menu.svg'
import Map                        from '../../../assets/mapIcon.svg'
import Reload                     from '../../../assets/reload.svg'
import Challenge                  from '../../../assets/challenges/notif.svg'
import { ButtonText, MediumText } from '../Texts'
import { DownArrowIcon }          from '../Icons'
import CheckIconButton            from '../../../assets/checkIconButton.svg'

const TextInButtonBase = styled.Text({
  fontFamily: 'Raleway_700Bold',
  fontWeight: 'bold',
  fontSize: 16,
  lineHeight: 19,
  textTransform: 'uppercase',
})

// ---------- Round button ----------
export const RoundButton = ({
                              onPress,
                              children,
                              color,
                              size,
                              disabled,
                              noShadow,
                              noColor,
                              ...props
                            }) => {
  const [hovered, hoverProps] = useHovered()
  const [isActive, isActiveProps] = useIsActive()

  return (
    <TouchableWithoutFeedback {...isActiveProps} {...{onPress, disabled}}>
      <RoundButtonWrapper
        {...{color, size, isActive, disabled, noShadow, noColor}}
        hover={hovered}
        {...hoverProps}
        {...props}
      >
        <IconWrapper {...{size}}>{children}</IconWrapper>
      </RoundButtonWrapper>
    </TouchableWithoutFeedback>
  )
}

const IconWrapper = styled.View(
  {
    position: 'relative',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({size}) => ({
    borderRadius: size / 2,
    width: size ? size : 50,
    height: size ? size : 50,
  })
)

const RoundButtonWrapper = styled.View(
  ({size, color, noColor}) => ({
    borderRadius: size / 2,
    backgroundColor: color ? color : noColor ? color : colors.primary,
  }),
  ({hover, isActive, disabled, noShadow}) =>
    disabled
      ? {opacity: 0.2}
      : !noShadow &&
      (isMobileApp
        ? {}
        : isActive
          ? {
            boxShadow:
              'inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)',
          }
          : hover
            ? {
              boxShadow:
                '1px 2px 5px rgba(94, 104, 121, 0.945), -1px -1px 4px #FFFFFF',
            }
            : {
              boxShadow:
                '1px 1px 3px rgba(94, 104, 121, 0.945), -1px -1px 4px #FFFFFF',
            })
)

export const SettingsButton = ({
                                 onPress,
                                 size,
                                 color = colors.primary,
                                 iconColor = colors.lightText,
                                 iconSize,
                                 ...props
                               }) => {
  return (
    <RoundButton size={size} color={color} onPress={onPress} {...props}>
      <Settings width={iconSize} height={iconSize} fill={iconColor}/>
    </RoundButton>
  )
}

export const ModalCheckButton = ({
  onPress,
  size,
  color,
  iconColor,
  iconSize,
  ...props
}) => {
  return (
    <RoundButton size={size} onPress={onPress} {...props} noShadow noColor>
      <CheckIconButton width={size} height={size} fill={color} stroke={color}/>
    </RoundButton>
  )
}

export const LangSelectorButton = ({
                                     onPress,
                                     size,
                                     color = colors.primary,
                                     iconColor = colors.lightText,
                                     iconSize,
                                     text,
                                     ...props
                                   }) => {
  return (
    <RoundButton onPress={onPress} color={color} size={size} {...props}>
      <LangText>
        {text}
      </LangText>
    </RoundButton>
  )
}

const LangText = styled(MediumText)({
  textTransform: 'uppercase',
  color: colors.white,
  alignSelf: 'center',
  borderWidth: 0,
  borderRadius: '50%',
  padding: 3
})

export const ArrowDownButton = ({
                                  onPress,
                                  size,
                                  color = colors.primary,
                                  iconColor = colors.lightText,
                                  iconSize,
                                  ...props
                                }) => {
  return (
    <RoundButton size={size} color={color} onPress={onPress} {...props}>
      <DownArrowIcon width={iconSize} height={iconSize} color={iconColor}/>
    </RoundButton>
  )
}

export const FocusButton = ({
                              onPress,
                              size,
                              color = colors.module,
                              iconColor = colors.lightText,
                              iconSize,
                              ...props
                            }) => {
  return (
    <RoundButton size={size} color={color} onPress={onPress} {...props}>
      <Focus width={iconSize} height={iconSize} fill={iconColor}/>
    </RoundButton>
  )
}

export const WorldMenuButton = ({
                                  onPress,
                                  size,
                                  color = colors.module,
                                  iconColor = colors.lightText,
                                  iconSize,
                                  ...props
                                }) => {
  return (
    <RoundButton size={size} color={color} onPress={onPress} {...props}>
      <WorldsMenu width={iconSize} height={iconSize} fill={iconColor}/>
    </RoundButton>
  )
}

export const HelperButton = ({
                               onPress,
                               size,
                               color,
                               iconColor,
                               iconSize,
                               ...props
                             }) => {
  return (
    <RoundButton size={size} color={color} onPress={onPress} {...props}>
      <Helper width={iconSize} height={iconSize} fill={iconColor}/>
    </RoundButton>
  )
}

export const LeaderboardButton = ({
                                    onPress,
                                    size,
                                    color = colors.primary,
                                    iconColor = colors.lightText,
                                    iconSize,
                                    ...props
                                  }) => {
  return (
    <RoundButton size={size} color={color} onPress={onPress} {...props}>
      <Leaderboard width={iconSize} height={iconSize} fill={iconColor}/>
    </RoundButton>
  )
}

export const HelpButton = ({
                             onPress,
                             size,
                             color,
                             iconColor,
                             iconSize,
                             ...props
                           }) => {
  return (
    <RoundButton size={size} color={color} onPress={onPress} {...props}>
      <InterrogationPoint width={iconSize} height={iconSize} fill={iconColor}/>
    </RoundButton>
  )
}

export const CloseButton = ({
                              onPress,
                              size,
                              color,
                              iconColor,
                              iconSize,
                              ...props
                            }) => {
  return (
    <RoundButton size={size} color={color} onPress={onPress} {...props}>
      <Cross width={iconSize} height={iconSize} fill={iconColor}/>
    </RoundButton>
  )
}

export const RightArrowButton = ({
                                   onPress,
                                   size,
                                   color,
                                   iconColor,
                                   iconSize,
                                   ...props
                                 }) => {
  return (
    <RoundButton size={size} color={color} onPress={onPress} {...props}>
      <RightArrow width={iconSize} height={iconSize} fill={iconColor}/>
    </RoundButton>
  )
}

export const ChallengeButton = ({
                                  onPress,
                                  size,
                                  color = colors.primary,
                                  iconColor = colors.lightText,
                                  iconSize,
                                  ...props
                                }) => {
  return (
    <RoundButton size={size} color={color} onPress={onPress} {...props}>
      <Challenge width={iconSize} height={iconSize} fill={iconColor}/>
    </RoundButton>
  )
}

export const ReloadButton = ({onPress, size, color, iconColor, ...props}) => {
  return (
    <RoundButton size={size} color={color} onPress={onPress} {...props}>
      <Reload width={size * 0.6} height={size * 0.6} fill={iconColor}/>
    </RoundButton>
  )
}

// ---------- Check button ----------
export const CheckButton = ({
                              onPress,
                              size,
                              iconColor,
                              iconColorActive,
                              iconSize,
                              disabled,
                              isActiveOverride,
                              ...props
                            }) => {
  const [hovered, hoverProps] = useHovered()
  const [isActive, isActiveProps] = useIsActive()

  return (
    <TouchableWithoutFeedback {...isActiveProps} {...{onPress, disabled}}>
      <CheckButtonWrapper
        hover={hovered}
        {...{size, isActive, isActiveOverride, disabled}}
        {...hoverProps}
        {...props}
      >
        <IconCheckWrapper
          hover={hovered}
          {...{size, isActive, isActiveOverride, disabled}}
          {...hoverProps}
        >
          <Done
            width={iconSize}
            height={iconSize}
            fill={
              (hovered && !disabled) || isActive || isActiveOverride
                ? iconColorActive
                : iconColor
            }
          />
        </IconCheckWrapper>
      </CheckButtonWrapper>
    </TouchableWithoutFeedback>
  )
}

const CheckButtonWrapper = styled.View(
  ({hover, size, isActive, isActiveOverride, disabled}) => ({
    borderRadius: (size + 4) / 2,
    ...(!hover &&
      !isActive &&
      !isActiveOverride &&
      !disabled && {
        shadowColor: colors.lightShadow,
        shadowOffset: {
          width: 0,
          height: 5,
        },
        shadowOpacity: 0.34,
        shadowRadius: 6.27,
        elevation: 10,
      }),
  })
)

const IconCheckWrapper = styled.View(
  {
    position: 'relative',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({hover, isActive, isActiveOverride, size, disabled}) => ({
    borderRadius: (size + 4) / 2,
    width: size ? size : 50,
    height: size ? size : 50,
    backgroundColor:
      isActive || isActiveOverride ? colors.secondary : colors.defaultBackground,
    ...(hover &&
      !disabled && {
        backgroundColor: colors.secondary,
      }),
    ...(isActive || isActiveOverride
      ? {
        ...(isMobileApp
          ? {
            borderWidth: 2,
            borderColor: colors.mobileButtonBorder,
          }
          : {
            padding: 2,
            boxShadow:
              'inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)',
          }),
      }
      : {
        borderWidth: 2,
        borderColor: colors.secondary,
      }),
    ...(disabled && {
      opacity: 0.2,
    }),
  })
)

// ---------- Text button ----------
export const TextButton = ({
                             children,
                             disabled,
                             color = colors.secondary,
                             backgroundColor,
                             onPress,
                             ...props
                           }) => {
  const [hovered, hoverProps] = useHovered()
  const [isActive, isActiveProps] = useIsActive()

  return (
    <TouchableWithoutFeedback {...{onPress, disabled}} {...isActiveProps}>
      <TextButtonWrapper
        hover={hovered}
        {...hoverProps}
        {...{isActive, disabled, backgroundColor, color}}
        {...props}
      >
        <TextWrapper
          hover={hovered}
          {...{isActive, disabled, backgroundColor, color}}
        >
          {children}
        </TextWrapper>
      </TextButtonWrapper>
    </TouchableWithoutFeedback>
  )
}

export const LinkTextButton = ({
                                 children,
                                 disabled,
                                 color = colors.secondary,
                                 backgroundColor,
                                 link,
                                 newTab,
                                 ...props
                               }) => {
  const [hovered, hoverProps] = useHovered()
  const [isActive, isActiveProps] = useIsActive()
  return (
    <a href={link} target={newTab ? '_blank' : null} style={{textDecoration: 'none'}}>
      <TouchableWithoutFeedback {...{disabled}} {...isActiveProps}>
        <TextButtonWrapper
          hover={hovered}
          {...hoverProps}
          {...{isActive, disabled, backgroundColor, color}}
          {...props}
        >
          <TextWrapper
            hover={hovered}
            {...{isActive, disabled, backgroundColor, color}}
          >
            {children}
          </TextWrapper>
        </TextButtonWrapper>
      </TouchableWithoutFeedback>
    </a>
  )
}

const TextButtonWrapper = styled.View(
  ({hover, isActive, disabled, backgroundColor, color}) => ({
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 1000,
    margin: 5,
    maxHeight: 39,
    paddingHorizontal: 18,
    backgroundColor: backgroundColor,
    ...(!disabled &&
      (hover || isActive) && {
        backgroundColor: color,
      }),
    ...(!disabled &&
      hover &&
      !isMobileApp && {
        boxShadow:
          '1px 1px 3px rgba(94, 104, 121, 0.945), -1px -1px 4px #FFFFFF',
      }),
    ...(isActive
      ? {
        ...(isMobileApp
          ? {
            paddingVertical: 6,
            borderWidth: 2,
            borderColor: colors.mobileButtonBorder,
          }
          : {
            paddingVertical: 8,
            marginHorizontal: 7,
            boxShadow:
              'inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)',
          }),
      }
      : {
        paddingVertical: 6,
        borderWidth: 2,
        borderColor: color ? color : colors.secondary,
      }),
    ...(disabled && {
      opacity: 0.2,
    }),
  })
)

const TextWrapper = styled(TextInButtonBase)(
  ({isActive, hover, disabled, color, backgroundColor}) => ({
    color: color ? color : colors.secondary,
    ...(!disabled &&
      (hover || isActive) && {
        cursor: 'pointer',
        color: backgroundColor ? backgroundColor : colors.lightText,
      }),
    ...(disabled && {
      cursor: 'default',
    }),
  })
)

// ---------- Text button with arrow ----------
export const TextArrowButton = ({
                                  children,
                                  disabled,
                                  color,
                                  backgroundColor,
                                  backgroundColorHover,
                                  onPress,
                                  textColor,
                                  textHoverColor,
                                  ...props
                                }) => {
  const [hovered, hoverProps] = useHovered()
  const [isActive, isActiveProps] = useIsActive()

  return (
    <TouchableWithoutFeedback {...{onPress, disabled}} {...isActiveProps}>
      <TextArrowButtonWrapper
        hover={hovered}
        {...hoverProps}
        isActive={isActive}
        disabled={disabled}
        backgroundColor={backgroundColor}
        {...props}
      >
        <TATextWrapper
          hover={hovered}
          {...hoverProps}
          isActive={isActive}
          disabled={disabled}
          backgroundColorHover={backgroundColorHover}
        >
          <TAText
            textColor={textColor}
            isActive={isActive}
            textHoverColor={textHoverColor}
            hover={hovered}
            width={'80%'}
          >
            {children}
          </TAText>
        </TATextWrapper>
        <TAArrowWrapper>
          <RightArrow width={'50%'} height={'50%'} fill={'#F2F2F2'}/>
        </TAArrowWrapper>
      </TextArrowButtonWrapper>
    </TouchableWithoutFeedback>
  )
}

const TextArrowButtonWrapper = styled.View(
  ({hover, isActive, disabled, backgroundColor}) => ({
    flexDirection: 'row',
    borderRadius: 1000,
    margin: 5,
    maxHeight: 35,
    backgroundColor: backgroundColor ? backgroundColor : colors.secondary,
    ...(!isActive &&
      !disabled && {
        boxShadow:
          '1px 1px 3px rgba(94, 104, 121, 0.945), -1px -1px 4px #FFFFFF',
      }),
    ...(isActive && {
      ...(isMobileApp
        ? {
          borderWidth: 2,
          borderColor: colors.mobileButtonBorder,
        }
        : {
          boxShadow:
            'inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)',
        }),
    }),
    ...(disabled && {
      opacity: 0.2,
    }),
  })
)

const TATextWrapper = styled.View(
  ({hover, isActive, disabled, backgroundColorHover}) => ({
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 18,
    borderTopLeftRadius: 1000,
    borderBottomLeftRadius: 1000,
    backgroundColor: isActive ? 'transparent' : colors.defaultBackground,
    ...(hover &&
      !disabled &&
      !isActive && {
        backgroundColor: backgroundColorHover
          ? backgroundColorHover
          : '#72D8CA',
      }),
  })
)

const TAArrowWrapper = styled.View({
  width: 30,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
})

const TAText = styled.Text(
  ({isActive, textColor, textHoverColor, hover}) => ({
    fontFamily: 'Raleway_700Bold',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 19,
    color:
      hover && textHoverColor
        ? textHoverColor
        : isActive
        ? '#F5F5F5'
        : textColor
          ? textColor
          : colors.text,
    textTransform: 'uppercase',
  })
)

// ---------- Little text button ----------
export const LittleTextButton = ({
                                   children,
                                   disabled,
                                   onPress,
                                   noHover,
                                   hoverColor = colors.secondaryLight,
                                   ...props
                                 }) => {
  const [hovered, hoverProps] = useHovered()
  const [isActive, isActiveProps] = useIsActive()

  return (
    <TouchableWithoutFeedback
      {...{onPress, disabled}}
      {...(!noHover && isActiveProps)}
    >
      <LittleTextButtonWrapper
        hover={noHover ? !noHover : hovered}
        {...(!noHover && hoverProps)}
        {...{isActive, disabled, hoverColor}}
        {...props}
        style={{cursor: noHover ? 'initial' : 'pointer'}}
      >
        <LittleTextWrapper hover={hovered} {...{isActive, disabled}}>
          {children}
        </LittleTextWrapper>
      </LittleTextButtonWrapper>
    </TouchableWithoutFeedback>
  )
}

const LittleTextButtonWrapper = styled.View(
  {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 1000,
    margin: 5,
    maxHeight: 20,
    paddingVertical: 3,
    paddingHorizontal: 15,
  },
  ({hover, isActive, disabled, hoverColor}) => ({
    ...(!isActive &&
      !disabled && {
        boxShadow:
          '1px 1px 3px rgba(94, 104, 121, 0.945), -1px -1px 4px #FFFFFF',
      }),
    backgroundColor: isActive
      ? colors.darkerBackground
      : colors.defaultBackground,
    ...(hover &&
      !disabled &&
      !isActive && {
        backgroundColor: hoverColor,
      }),
    ...(isActive && {
      ...(isMobileApp
        ? {
          borderWidth: 2,
          borderColor: colors.mobileButtonBorder,
        }
        : {
          boxShadow:
            'inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)',
        }),
    }),
    ...(disabled && {
      opacity: 0.2,
    }),
  })
)

const LittleTextWrapper = styled.Text({
  fontFamily: 'Raleway_500Medium',
  fontSize: 12,
  lineHeight: 14,
  color: colors.text,
})

// ---------- CTN text button ----------
export const CTNTextButton = ({
                                children,
                                iconName,
                                disabled,
                                notAllowed,
                                onPress,
                                ...props
                              }) => {
  const [hovered, hoverProps] = useHovered()
  const [isActive, isActiveProps] = useIsActive()

  const icons = {
    civiflouz: Civiflouz,
    civiflouzRound: CiviflouzRound,
    construction: Construction,
    cross: Cross,
    cubes: Cubes,
    drop: Drop,
    interrogationPoint: InterrogationPoint,
    padlock: Padlock,
    rightArrow: RightArrow,
    settings: Settings,
    tap: Tap,
    thunder: Thunder,
    thunderRound: ThunderRound,
  }

  const IconTag = icons[iconName || 'thunderRound']

  return (
    <TouchableWithoutFeedback
      {...isActiveProps}
      {...{onPress}}
      disabled={notAllowed ? notAllowed : disabled}
    >
      <CTNTextButtonWrapper
        hover={hovered}
        {...hoverProps}
        {...{isActive, disabled, notAllowed}}
        {...props}
      >
        <CTNTextWrapper
          hover={hovered}
          {...{isActive, disabled, notAllowed, iconName}}
        >
          {children}
        </CTNTextWrapper>
        {iconName && (
          <CTNIconWrapper>
            <IconTag
              width={'100%'}
              height={'100%'}
              fill={disabled || notAllowed ? colors.secondary : colors.lightText}
            />
          </CTNIconWrapper>
        )}
        {notAllowed && (
          <>
            <NotAllowedWrapper/>
            <Padlock
              width={'80%'}
              height={'80%'}
              fill={'#F2F2F2'}
              style={{position: 'absolute', padding: '10%'}}
            />
          </>
        )}
      </CTNTextButtonWrapper>
    </TouchableWithoutFeedback>
  )
}

const CTNTextButtonWrapper = styled.View(
  ({hover, isActive, disabled, notAllowed}) => ({
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 5,
    maxHeight: 35,
    borderRadius: 1000,
    transitionDuration: '300ms',
    transitionProperty: 'transform',
    transitionTimingFunction: 'ease-in-out',
    backgroundColor: disabled || notAllowed ? 'transparent' : colors.secondary,
    ...(isMobileApp
      ? {
        ...(!isActive &&
          !disabled &&
          !notAllowed && {
            shadowColor: colors.lightShadow,
            shadowOffset: {
              width: 0,
              height: 5,
            },
            shadowOpacity: 0.34,
            shadowRadius: 6.27,
            elevation: 10,
          }),
        ...(isActive && {
          borderWidth: 2,
          borderColor: colors.mobileButtonBorder,
        }),
      }
      : {
        ...(!isActive &&
          !disabled &&
          !notAllowed && {
            boxShadow:
              'inset -1px -2px 3px rgba(0, 154, 127, 0.5), 0px 5px 6.27px rgba(0, 0, 0, 0.34)',
          }),
        ...(hover &&
          !disabled &&
          !notAllowed &&
          !isActive && {
            boxShadow:
              'inset -1px -2px 3px rgba(0, 154, 127, 0.5), 0px 7px 9.51px rgba(0, 0, 0, 0.43)',
            transform: [{translateY: -2}],
          }),
        ...(isActive && {
          boxShadow:
            'inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)',
        }),
      }),
    ...((disabled || notAllowed) && {
      opacity: 0.5,
      borderWidth: 1,
    }),
    ...(disabled && {
      borderColor: colors.secondary,
    }),
    ...(notAllowed && {
      borderColor: colors.text,
    }),
  })
)

const CTNTextWrapper = styled.Text(({iconName, disabled, notAllowed}) => ({
  fontFamily: 'Raleway_700Bold',
  fontWeight: 'bold',
  fontSize: 16,
  lineHeight: 19,
  color: disabled || notAllowed ? colors.secondary : '#F2F2F2',
  ...(iconName
    ? {
      paddingVertical: 8,
      paddingLeft: 18,
    }
    : {
      paddingVertical: 8,
      paddingHorizontal: 18,
      textTransform: 'uppercase',
    }),
}))

const CTNIconWrapper = styled.View({
  width: 28,
  height: 28,
  marginLeft: 6,
  marginRight: 18,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
})

const NotAllowedWrapper = styled.View({
  backgroundColor: colors.text,
  position: 'absolute',
  width: '100%',
  height: '100%',
  borderRadius: 1000,
  opacity: 0.6,
})

// ---------- Action button ----------
export const ActionButton = ({
                               children,
                               disabled,
                               color = colors.lightText,
                               backgroundColor = colors.secondary,
                               onPress,
                               pnj,
                               mobile,
                               textFont,
                               icon,
                               ...props
                             }) => {
  const [hovered, hoverProps] = useHovered()
  const [isActive, isActiveProps] = useIsActive()
  return (
    <TouchableWithoutFeedback {...isActiveProps} {...{onPress, disabled}}>
      <ActionButtonWrapper
        hover={hovered}
        pnj={pnj}
        {...hoverProps}
        {...{isActive, disabled, backgroundColor}}
        {...props}
        mobile={mobile}
      >
        <ActionTextWrapper textFont={textFont} icon={icon}
                           {...{color}}>{children}</ActionTextWrapper>
      </ActionButtonWrapper>
    </TouchableWithoutFeedback>
  )
}

export const TabsButton = ({
                             children,
                             disabled,
                             color,
                             first,
                             last,
                             backgroundColor,
                             onPress,
                             icon,
                             selected,
                             activeTab,
                             ...props
                           }) => {
  const [hovered, hoverProps] = useHovered()
  const [isActive, isActiveProps] = useIsActive()
  return (
    <TouchableWithoutFeedback
      {...isActiveProps}
      {...{onPress, disabled}}
      onPress={() => {
        if (activeTab) {
          activeTab()
        }
        onPress()
      }}
    >
      <TabsButtonWrapper
        hover={hovered}
        {...hoverProps}
        {...{isActive, disabled, backgroundColor, first, last, selected}}
        {...props}
      >
        {icon}
        <ActionTabsWrapper {...{color, selected}}>
          {children}
        </ActionTabsWrapper>
      </TabsButtonWrapper>
    </TouchableWithoutFeedback>
  )
}

const ActionButtonWrapper = styled.View({
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 1000,
    margin: 5,
    maxHeight: 39,
    paddingVertical: 8,
    paddingHorizontal: 18,
    transitionDuration: '300ms',
    transitionProperty: 'transform',
    transitionTimingFunction: 'ease-in-out',
  },
  ({hover, isActive, disabled, backgroundColor, pnj, mobile}) => ({
    backgroundColor: backgroundColor,
    ...(disabled
      ? {opacity: 0.2}
      : isMobileApp
        ? {} //TODO: Gérer l'affichage mobile
        : isActive
          ? {
            boxShadow:
              'inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)',
          }
          : hover
            ? {
              transform: [{translateY: -2}],
              boxShadow:
                '1px 2px 5px rgba(94, 104, 121, 0.945), -1px -1px 4px #FFFFFF',
            }
            : {
              boxShadow:
                '1px 1px 3px rgba(94, 104, 121, 0.945), -1px -1px 4px #FFFFFF',
            }),
  })
)

const TabsButtonWrapper = styled.View(
  {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 5,
    maxHeight: 39,
    paddingVertical: 8,
    paddingHorizontal: 18,
    transitionDuration: '300ms',
    transitionProperty: 'transform',
    transitionTimingFunction: 'ease-in-out',
    display: 'flex',
    flexDirection: 'row',
  },
  ({hover, isActive, disabled, backgroundColor, first, last, selected}) => ({
    backgroundColor:
      backgroundColor && selected ? backgroundColor : colors.darkerBackground,
    ...(first && {
      borderBottomLeftRadius: 5,
      borderTopLeftRadius: 5,
    }),
    ...(last && {
      borderBottomRightRadius: 5,
      borderTopRightRadius: 5,
    }),
    ...(disabled
      ? {opacity: 0.2}
      : isMobileApp
        ? {} //TODO: Gérer l'affichage mobile
        : isActive
          ? {
            boxShadow:
              'inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)',
          }
          : hover
            ? {
              transform: [{translateY: -2}],
              boxShadow:
                '1px 2px 5px rgba(94, 104, 121, 0.945), -1px -1px 4px #FFFFFF',
            }
            : {
              boxShadow:
                '1px 1px 3px rgba(94, 104, 121, 0.945), -1px -1px 4px #FFFFFF',
            }),
  })
)

const ActionTextWrapper = styled(TextInButtonBase)(({color, textFont, icon}) => ({
  color: color,
  fontSize: textFont || 16,
  display: icon && "flex",
  alignItems: icon && "center"
}))

const ActionTabsWrapper = styled(TextInButtonBase)(({color, selected}) => ({
  color: color && selected ? color : colors.text,
  textTransform: 'none',
  margin: 20,
}))

export const DefaultSwitch = ({defaultValue, onPress}) => {
  const [switchStatus, setSwitchStatus] = useState(defaultValue || false)
  const moveSwitch = useRef(new Animated.Value(0)).current

  const switchAnimation = () => {
    if (switchStatus) {
      Animated.timing(moveSwitch, {
        toValue: -45,
        duration: 500,
      }).start(() => {
        moveSwitch.setValue(0)
        setSwitchStatus((e) => !e)
      })
    } else {
      Animated.timing(moveSwitch, {
        toValue: 45,
        duration: 500,
      }).start(() => {
        moveSwitch.setValue(0)
        setSwitchStatus((e) => !e)
      })
    }
  }

  return (
    <WrapperSwitch
      onClick={() => {
        switchAnimation()
        onPress()
      }}
    >
      <SwitchBall
        status={switchStatus}
        style={{transform: [{translateX: moveSwitch}]}}
      />
    </WrapperSwitch>
  )
}

const WrapperSwitch = styled.TouchableHighlight({
  width: 75,
  height: 30,
  boxShadow:
    'inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)',
  borderRadius: 25,
  backgroundColor: colors.defaultBackground,
  padding: 5,
})

const SwitchBall = styled(Animated.View)(
  {
    width: 20,
    height: 20,
    boxShadow:
      'inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)',
    borderRadius: 50,
    position: 'absolute',
  },
  ({status}) => ({
    backgroundColor: status ? colors.secondary : colors.linkWater,
    right: status ? 5 : 'unset',
    left: status ? 'unset' : 5,
  })
)

export const ReturnToMapButton = ({text, onPress, mobile, ...props}) => {
  const [hovered, hoverProps] = useHovered()
  const [isActive, isActiveProps] = useIsActive()
  return (
    <TouchableWithoutFeedback
      {...isActiveProps}
      {...hoverProps}
      onPress={onPress}
    >
      <ReturnToMapButtonWrapper
        color={
          !isActive && !hovered ? colors.defaultBackground : colors.secondary
        }
        isActive={isActive}
        mobile={mobile}
        {...props}
      >
        <Map
          fill={
            isActive || hovered ? colors.defaultBackground : colors.secondary
          }
        />
        <ReturnToMapText
          color={
            isActive || hovered ? colors.defaultBackground : colors.secondary
          }
          mobile={mobile}
        >
          {text}
        </ReturnToMapText>
      </ReturnToMapButtonWrapper>
    </TouchableWithoutFeedback>
  )
}

const ReturnToMapButtonWrapper = styled.View(({color, isActive, mobile}) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: color,
  boxShadow: isActive
    ? 'inset -1px -1px 3px rgba(255, 255, 255, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)'
    : '-1px -1px 4px #FFFFFF, 1px 1px 3px rgba(94, 104, 121, 0.945)',
  borderRadius: 17.5,
  padding: mobile ? 3 : 10,
  alignItems: 'center',
}))

const ReturnToMapText = styled(ButtonText)(({color, mobile}) => ({
  marginLeft: 10,
  marginRight: 10,
  color: color,
  fontSize: mobile ? 11 : 'none',
}))

export const FragmentButton = ({text, onPress, mobile, ...props}) => {
  const [hovered, hoverProps] = useHovered()
  const [isActive, isActiveProps] = useIsActive()
  return (
    <TouchableWithoutFeedback
      {...isActiveProps}
      {...hoverProps}
      onPress={onPress}
    >
      <FragmentButtonWrapper
        color={
          !isActive && !hovered ? colors.defaultBackground : colors.secondary
        }
        isActive={isActive}
        mobile={mobile}
        {...props}
      >
        <FragmentText
          color={
            isActive || hovered ? colors.defaultBackground : colors.secondary
          }
          mobile={mobile}
        >
          {text}
        </FragmentText>
      </FragmentButtonWrapper>
    </TouchableWithoutFeedback>
  )
}

const FragmentButtonWrapper = styled.View(({isActive, mobile, color}) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: color,
  borderWidth: '1px',
  borderColor: colors.secondary,
  borderRadius: 17.5,
  padding: mobile ? 5 : 10,
  alignItems: 'center',
  paddingLeft: mobile ? 20 : 50,
  paddingRight: mobile ? 20 : 50,
  justifyContent: "center",
  width: "fit-content",
  alignSelf: 'center'
}))

const FragmentText = styled(ButtonText)(({mobile, color}) => ({
  color: color,
  fontSize: mobile ? 12 : 'none',
}))
