import {campaignGet, sendEvent, stateGet} from '../../../../saga-tools/Data.effects'
import {createFunctionalError}            from "../../../../saga-tools/Errors"
import {profileErrors}                    from "../../../Profile/Profile.errors"

export function* seeCampaignEndedModal(command) {
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)
  const userState = yield stateGet(command.emitter)

  if(userState){
    yield sendEvent("CampaignEndedModalSeen", {})
  }
}

export function* reInitializeGameAccount(command) {
  const campaign = yield campaignGet()
  if (!campaign) throw createFunctionalError(profileErrors.campaignDoesntExist)
  const userState = yield stateGet(command.emitter)
  if(userState){
    yield sendEvent("GameAccountReInitialized", {
    })
  }
}
