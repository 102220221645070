import { getContext, call } from 'redux-saga/effects'
import { generateId } from './Randoms'

export function random () {
  return call(randomFromContext)
}

export function getNewId () {
  return call(generateIdFromContext)
}

function * generateIdFromContext () {
  const rand = yield getContext('random')
  const date = yield getContext('date')

  return generateId(date, rand.int32)
}

function * randomFromContext () {
  const rand = yield getContext('random')

  return rand()
}
