import {campaignSet, getCampaignId, getClientId} from "../../../../saga-tools/Data.effects"
import {all, call} from "redux-saga/effects"

export function* phase2ModulePreferencesEnded(event) {
  const userId = event.target
  const moduleType = 'preferences'
  const moduleStep = event?.moduleStep
  const moduleTheme = event?.moduleTheme
  const teamId = event?.teamId
  const data = event?.result

  yield campaignSet(`content/modules/${moduleType}/${moduleStep}/${moduleTheme}/${teamId}/${userId}`,
    () => data?.result
  )
}

export function* phase2ModulePictionaryEnded(event) {
  const clientId = yield getClientId()
  const campaignId = yield getCampaignId()
  const userId = event.target
  const moduleType = 'pictionary'
  const moduleStep = event?.moduleStep
  const moduleTheme = event?.moduleTheme
  const teamId = event?.teamId
  const data = event?.result
  const playerFullOnBoarding = event?.playerFullOnBoarding

  const drawUrl = `https://ct-campaigns.civitimeapp.com/clients/${clientId}/campaigns/${campaignId}/pictionary/${userId}/${data?.draw?.name}.png`
  if(!playerFullOnBoarding){
    yield campaignSet(`content/modules/${moduleType}/${moduleStep}/${moduleTheme}/${data?.guess?.name}/teams/${teamId}/${data?.guess?.opponentId}/${userId}`,
        () => data?.guess?.answer
    )
  }
  yield campaignSet(`content/modules/${moduleType}/${moduleStep}/${moduleTheme}/${data?.draw?.name}/draws/${userId}/url`,
    () => drawUrl
  )
}

export function* phase2ModuleGoldenCompanyEnded(event) {
  const userId = event.target
  const moduleType = 'goldenCompany'
  const moduleStep = event?.moduleStep
  const moduleTheme = event?.moduleTheme
  const teamId = event?.teamId
  const data = event?.result
  const wrongAnswers = data?.result?.wrongAnswers || []
  const playerFullOnBoarding = event?.playerFullOnBoarding

  if(!playerFullOnBoarding){
    yield campaignSet(`content/modules/${moduleType}/${moduleStep}/${moduleTheme}/${teamId}/${userId}`,
        () => data?.result?.firstChoice
    )
  }

  if (wrongAnswers?.length) {
    yield all(
      wrongAnswers.map(answer =>
        call(saveAffinities, {
          ...event,
          answer: answer
        })
      ))
  }
}

function* saveAffinities(command) {
  const moduleType = 'goldenCompany'
  const moduleStep = command?.moduleStep
  const moduleTheme = command?.moduleTheme
  const wrongAnswer = command?.answer

  yield campaignSet(`content/modules/${moduleType}/${moduleStep}/${moduleTheme}/wrongAnswers`,
    (answers) => ({
      ...answers,
      [wrongAnswer]: (answers?.[wrongAnswer] || 0) + 1
    })
  )
}
