import { campaignGet, campaignSet } from '../../../../saga-tools/Data.effects'

export function* phase2UpdateTutoStepSeen(event) {
  try {
    const userId = event?.target
    const step = event?.step
  
    yield campaignSet(`sessions/${userId}/progressionSeen/tuto/${step}`,
      () => true)
  }
  catch (e) {
    console.log(e)
  }
  
}
