import { call, getContext, put } from 'redux-saga/effects'
import { getNewId }              from './Random.effects'

export const SET_DATA = "SET_DATA"
export const GET_DATA = "GET_DATA"

export const set = (path, value) => {
  return {
    type: SET_DATA,
    payload: {
      path,
      value,
    },
  }
}
export const get = (path) => ({
  type: GET_DATA,
  payload: {
    path: path,
  },
})

export const dataMiddleware = (accessor) => (next) => (effect) => {
  const reducedEffect = () => {
    switch (effect.type){
      case "SELECT":
        return accessor.get(effect.payload.path)
      case 'GET_DATA':
        return accessor.get(effect.payload.path)
      case 'SET_DATA':
        return accessor.set(effect.payload.path, effect.payload.value)
      default:
        return effect
    }
  }
  next(reducedEffect())
}

export function getCurrentUserId() {
  return getContext("currentUserId")
}

export function getNow() {
  return getContext("messageDate")
}

export function getClientId() {
  return getContext("clientId")
}

export function getCampaignId() {
  return getContext("campaignId")
}

function* publicProfileGetEffect(userId) {
  const clientId = yield getClientId()
  const pathValue = userId ? `/${userId}` : ""

  return yield get(`clients/${clientId}/profiles${pathValue}`)
}

function* stateGetEffect(userId) {
  const clientId = yield getClientId()
  const campaignId = yield getCampaignId()
  return yield get(`clients/${clientId}/campaigns/${campaignId}/states/${userId}`)
}

export function publicProfileGet(userId) {
  return call(publicProfileGetEffect, userId)
}

export function stateGet(userId) {
  return call(stateGetEffect, userId)
}

function* sessionGetEffect(userId) {
  const clientId = yield getClientId()
  const campaignId = yield getCampaignId()
  return yield get(`clients/${clientId}/campaigns/${campaignId}/sessions/${userId}`)
}

export function contentGet(moduleId) {
  return call(contentGetEffect, moduleId)
}

function* contentGetEffect(moduleId) {
  const clientId = yield getClientId()
  const campaignId = yield getCampaignId()
  return yield get(`clients/${clientId}/campaigns/${campaignId}/content/${moduleId}`)
}

export function sessionGet(userId) {
  return call(sessionGetEffect, userId)
}

function* publicProfileSetEffect(userId, update) {
  const clientId = yield getClientId()
  const pathValue = userId ? `/${userId}` : ""
  const completePath = `clients/${clientId}/profiles${pathValue}`
  return yield set(completePath, update)
}

function* stateSetEffect(userId, file) {
  const clientId = yield getClientId()
  const campaignId = yield getCampaignId()
  const completePath = `clients/${clientId}/campaigns/${campaignId}/${userId}`
  return yield set(completePath, file)
}

export function publicProfileSet(userId, data) {
  return call(publicProfileSetEffect, userId, data)
}
export function stateSet(userId, file) {
  return call(stateSetEffect, userId, file)
}

function* campaignGetEffect(path) {
  const clientId = yield getClientId()
  const campaignId = yield getCampaignId()
  const pathValue = path ? `/${path}` : ""
  return yield get(`clients/${clientId}/campaigns/${campaignId}${pathValue}`)
}

export function campaignGet(path) {
  return call(campaignGetEffect, path)
}

function* campaignSetEffect(path, value) {
  const clientId = yield getClientId()
  const campaignId = yield getCampaignId()
  const pathValue = path ? `/${path}` : ""
  const completePath = `clients/${clientId}/campaigns/${campaignId}${pathValue}`
  return yield set(completePath, value)
}

export function campaignSet(path, value) {
  return call(campaignSetEffect, path, value)
}

export function sendEvent(type, payload) {
  return call(wrappedSendEvent, type, payload)
}
function* wrappedSendEvent(type, payload) {
  yield put({
    id: yield getNewId(),
    type,
    payload: {
      clientId: yield getClientId(),
      campaignId: yield getCampaignId(),
      target: yield getCurrentUserId(),
      timeStamp: yield getNow(),
      ...payload,
    },
  })
}
