import React    from 'react'
import { View } from 'react-native'
import styled   from '@emotion/native'

import { colors }              from '../../configs/colors'
import { useIsActive }         from '../../hooks/useIsActive'
import Square                  from '../../../assets/teamIcon/square.svg'
import First                   from '../../../assets/rankIcon/first.svg'
import Second                  from '../../../assets/rankIcon/second.svg'
import Third                   from '../../../assets/rankIcon/third.svg'
import Triangle                from '../../../assets/teamIcon/triangle.svg'
import Building1Icon from '../../../assets/teamIcon/building1.svg'
import Building2Icon from '../../../assets/teamIcon/building2.svg'
import Building3Icon from '../../../assets/teamIcon/building3.svg'
import Round                   from '../../../assets/teamIcon/round.svg'
import { BoldText, Cartridge } from '../Texts'
import { useHovered }          from '../../hooks/useHovered'

const blazonIcons = {
  1: {
    square: Square,
    triangle: Triangle,
    round: Round,
  },
  1.3: {
    square: Square,
    triangle: Triangle,
    round: Round,
  },
  2: {
    square: Building1Icon,
    triangle: Building2Icon,
    round: Building3Icon
  }
}

export const getRankIcon = (rank) => {
  switch (rank) {
    case 1:
      return First
    case 2:
      return Second
    case 3:
      return Third
    default:
      return null
  }
}

export const Blazon = ({
                         blazon,
                         color,
                         text,
                         size,
                         name,
                         onPress,
                         phase,
                         ...props
                       }) => {
  const DEFAULT_BLAZON = 'square'
  const DEFAULT_COLOR = '#D9856C'
  const DEFAULT_NAME = ' '
  const [hovered, hoverProps] = useHovered()
  const [isActive, isActiveProps] = useIsActive()
  const Icon = blazon ? blazonIcons?.[phase]?.[blazon] : blazonIcons?.[phase]?.[DEFAULT_BLAZON]
  const disabled = !onPress
  return (
    <View {...props}>
      <WrapperTouchableBlazon
        {...{onPress, disabled}}
        {...hoverProps}
        {...isActiveProps}
      >
        <WrapperBlazonWithName>
          <WrapperBlazon>
            <Icon width={size} height={size} {...{currentColor: color ? color : DEFAULT_COLOR}}/>
            {text && (phase === 1 || phase === 1.3) && <BlazonText {...{size}}>{text}</BlazonText>}
          </WrapperBlazon>
          {name && (
            <BlazonCartridge {...{disabled, isActive, hovered}}>
              {name || DEFAULT_NAME}
            </BlazonCartridge>
          )}
        </WrapperBlazonWithName>
      </WrapperTouchableBlazon>
    </View>
  )
}

const WrapperBlazonWithName = styled.View({
  alignItems: 'center'
})

export const LeaderboardBlazon = ({
                                    rank,
                                    blazon,
                                    color,
                                    text,
                                    size,
                                    name,
                                    phase,
                                    ...props
                                  }) => {
  const RankIcon = getRankIcon(rank)
  return (
    <WrapperBlazonWithCartridge {...props}>
      {rank && (
        <WrapperRankIcon>
          <RankIcon height={size - 10} width={size - 10}/>
        </WrapperRankIcon>
      )}
      <Blazon {...{blazon, color, text, size, name, phase}} />
    </WrapperBlazonWithCartridge>
  )
}

const WrapperBlazon = styled.View({
  justifyContent: 'center',
  alignItems: 'center',
})

const WrapperTouchableBlazon = styled.TouchableWithoutFeedback({})

const BlazonText = styled(BoldText)(
  {
    position: 'absolute',
    color: colors.lightText,
  },
  ({size}) => ({
    fontSize: size / 2,
    top: size / 2,
    left: size / 2,
    transform: [{translateX: '-50%'}, {translateY: '-50%'}]
  })
)

const BlazonCartridge = styled(Cartridge)(
  {
    marginTop: -10,
    paddingHorizontal: 20,
  },
  ({disabled, isActive, hovered}) =>
    !disabled && {
      boxShadow: isActive
        ? 'inset -1px -1px 3px rgba(21, 19, 19, 0.6), inset 1px 1px 3px rgba(94, 104, 121, 0.692)'
        : hovered
          ? 'rgba(94, 104, 121, 0.945) 1px 0px 4px'
          : 'rgba(94, 104, 121, 0.945) 1px 0px 2px',
    }
)

const WrapperBlazonWithCartridge = styled.View({
  display: 'flex',
  alignItems: 'center'
})

export const WrapperRankIcon = styled.View({
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: -8,
  zIndex: 2,
  alignSelf: 'center',
})