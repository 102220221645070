import { sendEvent } from '../../../../saga-tools/Data.effects'

export function * seePNJModal(command){
  yield sendEvent('PNJModalSeen', {...command})
}

export function * lanchModule(command){
  yield sendEvent('ModuleLaunched', {...command})
}

export function * modalEndedPNJDuration(command){
  yield sendEvent('ModalEndedPNJDuration', {...command})
}
